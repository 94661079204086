import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Row, Col, Card } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  defaults
} from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../App';
import Cookies from 'js-cookie'

defaults.font.family = 'Maven Pro';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// const mfoptions = {
//   responsive: true,
//   plugins: {
//     title: {
//       display: false,
//     },
//     legend: {
//       display: true,
//       position: "bottom",
//       labels: {
//         boxWidth: 10,
//         boxHeight: 10,
//         usePointStyle: true,
//         pointStyle: 'rectRounded',
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       },
//       tooltip: {
//         bodyFont: {
//           family: "Maven Pro"
//         },
//         titleFont: {
//           family: "Maven Pro"
//         }
//       }
//     },
//   },
// }

// const mfdata = {
//   labels: ['Live', 'Default ', 'Main', 'Sub'],
//   datasets: [
//     {
//       label: 'Mutual Fund',
//       data: [10, 20, 40, 30],
//       backgroundColor: ['#ebd8b7', '#b07b9c', '#bff5ee', '#c29ee8']
//     },
//   ],
// }

// Loan Doughnut Chart
// const loanoptions = {
//   responsive: true,
//   plugins: {
//     title: {
//       display: false,
//     },
//     legend: {
//       display: true,
//       position: "bottom",
//       labels: {
//         boxWidth: 10,
//         boxHeight: 10,
//         usePointStyle: true,
//         pointStyle: 'rectRounded',
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       },
//       tooltip: {
//         bodyFont: {
//           family: "Maven Pro"
//         },
//         titleFont: {
//           family: "Maven Pro"
//         }
//       }
//     },
//   },
// };
// const loandata = {
//   labels: ['Live', 'Default ', 'Main', 'Sub'],
//   datasets: [
//     {
//       label: 'Loan',
//       data: [20, 30, 40, 35],
//       backgroundColor: ['#e8ae99', '#7a5d46', '#d6e087', '#b7f7c8', '#DB73FF']
//     },
//   ],
// };

// Fuel Bar Chart
// const fueloptions = {
//   responsive: true,
//   plugins: {
//     title: {
//       display: false,
//     },
//     legend: {
//       display: false,
//       tooltip: {
//         bodyFont: {
//           family: "Maven Pro"
//         },
//         titleFont: {
//           family: "Maven Pro"
//         }
//       }
//     },
//   },
//   scales: {
//     y: {
//       ticks: {
//         stepSize: 20,
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       },
//       grid: {
//         display: true,
//       },
//       scaleLabel: {
//         display: true,
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       }
//     },
//     x: {
//       ticks: {
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       },
//       grid: {
//         display: false,
//       },
//       scaleLabel: {
//         display: true,
//         font: {
//           family: "Maven Pro",
//           size: 14
//         }
//       }
//     }
//   }
// };

// const fueldata = {
//   labels: ['Live', 'Default', 'Main', 'Sub', 'Stories', 'Widgets'],
//   datasets: [
//     {
//       label: 'Fuel',
//       data: [90, 100, 105, 89, 102, 101],
//       barThickness: 20,
//       backgroundColor: '#88d8e3',
//     }
//   ],
// };


const Home = () => {
  const navigate = useNavigate()
  const [total, settoal] = useState(0)
  const [loading, Setloading] = useState(true)
  const [defulttotal, setdefulttotal] = useState(0)
  const [maintotal, setmaintotal] = useState(0)
  const [subtotal, setsubtotal] = useState(0)
  const [themetotal, setthemetotal] = useState(0)
  const [storytotal, setstorytotal] = useState(0)
  const [widgettotal, setwidgettotal] = useState(0)
  const [uploadtotal, setuploadtotal] = useState(0)
  const [dashthemetotal, setdashthemetotal] = useState(0)
  const [dashwidtotal, setdashwidtotal] = useState(0)

  const getdata = async () => {
    const res = await API.post("/total")
    settoal(res.data.data)


  }
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/homePageApi", {}, { headers: { "authorization": `Bearer ${token}` } })
    if (response.data.response_code === 0) {
      navigate("/")
    }
  }


  const defultoal = async () => {
    const res = await API.post("/totaldefu")
    setdefulttotal(res.data.data)
  }

  const maintotals = async () => {
    const res = await API.post('/maintotal')
    setmaintotal(res.data.data)
  }

  const subtotals = async () => {
    const res = await API.post('/subtotal')
    setsubtotal(res.data.data)
  }

  const totaltheme = async () => {
    const res = await API.post('/totaltheme')
    setthemetotal(res.data.data)
  }

  const storytheme = async () => {
    const res = await API.post('/storytotal')
    setstorytotal(res.data.data)
  }

  const widtheme = async () => {
    const res = await API.post('/widtotal')
    setwidgettotal(res.data.data)
  }

  const uploadstotal = async () => {
    const res = await API.post('/uploatotal')
    setuploadtotal(res.data.data)
  }


  const dashthemestotal = async () => {
    const res = await API.post('/dashthemetotal')
    setdashthemetotal(res.data.data)
  }

  const dashwidstotal = async () => {
    const res = await API.post('/dashwidtotal')
    setdashwidtotal(res.data.data)
  }

  useEffect(() => {
    tokenAuth()
    getdata()
    defultoal()
    maintotals()
    subtotals()
    totaltheme()
    storytheme()
    widtheme()
    uploadstotal()
    dashthemestotal()
    dashwidstotal()
  }, [])



  return (
    <Layout sidebar={true}>
      <>
        <div className="vv-dashboard">
          <Row>
            <Col xxl={3} xl={4} md={6}>
              <h3><span>Theme</span></h3>
              <Card>
                <Card.Body>
                  <Link to='/livetheme'>
                    <div className="counter orange">
                      <div className="counter-media">
                        <i className='bx bxs-brush-alt' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{total}</h3>
                        <p>Live Theme</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Link to='/defaulttheme'>
                    <div className="counter pink">
                      <div className="counter-media">
                        <i className='bx bxs-paint-roll' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{defulttotal}</h3>
                        <p>Default Theme</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={4} md={6}>
              <h3><span>Categories</span></h3>
              <Card>
                <Card.Body>
                  <Link to='/maincatgory'>
                    <div className="counter green">
                      <div className="counter-media">
                        <i className='bx bxs-category-alt' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{maintotal}</h3>
                        <p>Main Categories</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Link to="/subcatgory">
                    <div className="counter blue">
                      <div className="counter-media">
                        <i className='bx bxs-collection'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{subtotal}</h3>
                        <p>Sub Categories</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={4} md={6}>
              <h3><span>Icon</span></h3>
              <Card>
                <Card.Body>
                  <Link to="/themeicon">
                    <div className="counter red">
                      <div className="counter-media">
                        <i className='bx bxs-palette' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{themetotal}</h3>
                        <p>Theme Icon</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Link to='/dashboradtheme'>
                    <div className="counter purple">
                      <div className="counter-media">
                        <i className='bx bxs-brush' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{dashthemetotal}</h3>
                        <p>DashBorad Theme</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={4} md={6}>
              <h3><span>Data</span></h3>
              <Card>
                <Card.Body>
                  <Link to='/stories'>
                    <div className="counter yellow">
                      <div className="counter-media">
                        <i className='bx bxs-video-recording'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{storytotal}</h3>
                        <p>Stories</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Link to='/uploadata'>
                    <div className="counter rani">
                      <div className="counter-media">
                        <i className='bx bxs-cloud-upload' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{uploadtotal}</h3>
                        <p>Upload Data</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={4} md={6}>
              <h3><span>Widgets</span></h3>
              <Card>
                <Card.Body>
                  <Link to='/widgets'>
                    <div className="counter sky">
                      <div className="counter-media">
                        <i className='bx bxs-widget'></i>
                      </div>
                      <div className="counter-content">
                        <h3>{widgettotal}</h3>
                        <p>Widgets</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Link to='/dashbordwidgets'>
                    <div className="counter stone">
                      <div className="counter-media">
                        <i className='bx bxs-dashboard' ></i>
                      </div>
                      <div className="counter-content">
                        <h3>{dashwidtotal}</h3>
                        <p>DashBorad Widgets</p>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </Layout>
  )
}


export default Home
