import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { API } from "../../App"
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'


const UploaddataEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false)
  const [cat, setCategory] = useState([])

  const lodadata = async () => {
    setLoading(true)
    const token = Cookies.get('jwt')
    const res = await API.post(`/uploadDatas/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })

    setuser({

      name: res.data.data.name,
      zip_name: res.data.data.zip_name,

    })
    setLoadData(res.data.data)
    const catRes = await API.post(`/uploadDatas`, {}, { headers: { "authorization": `Bearer ${token}` } })
    setCategory(catRes.data.data)
    setLoading(false)
  }

  useEffect(() => {
    lodadata()
  }, [])


  const [user, setuser] = useState({
    name: "",

  })

  const handdelsubmission = async () => {

    if (user.name === "" || !user.name) {
      setValidate(true)
      console.log("helo2")
    }
    else {
      console.log('test')
      const formdata = new FormData()
      formdata.append("zip_name", selectedfile)
      formdata.append("name", user.name)

      setLoading(true)
      const token = Cookies.get('jwt')
      const res = await API.post(`/uploadDatasUpdate/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
      console.log(res, 'res')
      if (res.data.status === true) {
        navigate("/uploadata")
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }


  const onInputchange = (e) => {
    console.log(e.target.value)
    setuser({ ...user, [e.target.name]: e.target.value })

  }

  const changeHandler = (event) => {
    setSelectedfile(event.target.files[0]);
    setIsSelected(true);

  }


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Edit Upload Data</h3>
        <Row className="align-items-center">
        </Row>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.name} name="name" placeholder='Enter Name' onChange={onInputchange} required />
                        <Form.Control.Feedback type="invalid">
                          Select Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >Zip Link</Form.Label>
                        <div className='side-img'>
                          <Form.Control type="file" id='cate-img' name='zip_name' className="my-2" accept='.zip,.7zip,.rar' onChange={changeHandler} />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/uploadata">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default UploaddataEdit