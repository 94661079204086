import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import Pagination from 'rc-pagination';
import { API } from '../../App';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'

const StoriesEdit = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [cat, setCategory] = useState([])
  const [loading, Setloading] = useState(false)
  // const [status, setstatus] = useState(true)
  // const [is_premium, setis_premium] = useState(true)


  const lodadata = async () => {
    Setloading(true)
    const token = Cookies.get('jwt')
    const res = await API.post(`/stories/${id}`)
    console.log(res.data, "edit");
    setuser({
      category_id: res.data.data[0].category_id,
      type: res.data.data[0].type,
      main_image: res.data.data[0].story_images_data,
      front_image: res.data.data[0].story_images_data[0].front_image,
      zip_name: res.data.data[0].story_images_data[0].zip_name,
      is_premium: res.data.data[0].story_images_data[0].is_premium,
      status: res.data.data[0].story_images_data[0].status,
      created_at: res.data.data[0].created_at,
      updated_at: res.data.data[0].updated_at

    })
    setLoadData(res.data.data)
    var new_data = []
    res.data.data[0].story_images_data
      .map((val) => {

        new_data.push(
          {
            story_id: val._id,
            main_image: val.main_image,
            front_image: val.front_image,
            zip_name: val.zip_name,
            is_premium: val.is_premium,
            status: val.status
          }
        )
      })
    setKey(new_data)
    const catRes = await API.post(`/Story_category`)
    console.log(catRes, "--------cates")
    setCategory(catRes.data.data[0].get_SubCategory)
    Setloading(false)
  }


  const [user, setuser] = useState({
    rank: "",
    category_id: "",
    type: "",
    theme_icons_data: []
  })


  const handdelsubmission = async () => {

    if (user.category_id === "" || !user.category_id) {
      setValidate(true)
      console.log('helo2')
    }

    else {
      const formdata = new FormData()
      console.log(user.category_id, "catDI");
      formdata.append("category_id", typeof (user.category_id) === 'string' ? user.category_id : user.category_id._id)
      formdata.append("type", user.type)

      if (key.length === 1) {
        if (key[0].icon === "") {
          setValidate(true)

          return
        }
        if ((key[0].main_image === "")) {
          setValidate(true)

          return
        }
        if ((key[0].front_image === "")) {
          setValidate(true)

          return
        }
        formdata.append('story_id', key[0].story_id)
        formdata.append('main_image', key[0].main_image)
        formdata.append('front_image', key[0].front_image)
        formdata.append('zip_name', key[0].zip_name)
        formdata.append('status', key[0].status)
        formdata.append('is_premium', key[0].is_premium)
      } else {
        key.map((val) => {
          if ((val.main_image === "")) {
            setValidate(true)

            return
          }
          if ((val.front_image === "")) {
            setValidate(true)

            return
          }
          if ((val.zip_name === "")) {
            setValidate(true)
          }
          formdata.append('story_id', val.story_id)
          formdata.append('main_image', val.main_image)
          formdata.append('front_image', val.front_image)
          formdata.append('zip_name', val.zip_name)
          formdata.append('status', parseInt(val.status))
          formdata.append('is_premium', parseInt(val.is_premium))
        })
      }
      Setloading(true)
      const token = Cookies.get('jwt')
      const res = await API.post(`/storiesUpdate/${id}`, formdata)
      console.log(res, "ressss")
      if (res.data.status === true) {

        navigate("/stories")
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }
      else {
        Setloading(false)
      }

    }
  }


  useEffect(() => {
    lodadata()
  }, [])

  const onInputchange = (e) => {
    console.log(e.target.value)
    setuser({ ...user, [e.target.name]: e.target.value })

  }

  //key
  const [key, setKey] = useState([])
  const addKey = (tag1, name) => {
    const keyTag = {
      story_id: "",
      main_image: undefined,
      front_image: undefined,
      zip_name: undefined,
      is_premium: 1,
      status: 1
    }
    setKey([...key, keyTag])
  }



  const keyChange = async (e, p, index, name) => {
    console.log(name, p, "pepepepepepep")
    if (name === "main_image") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "front_image") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "zip_name") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "status") {
      console.log(p, "--------------------------------------------")
      const result = (e === true) ? 1 : 0
      let data = [...key];
      data[index]['status'] = result
      const form = new FormData()
      form.append("name", name)
      form.append('staus', (e === true) ? 1 : 0)
      form.append('id', p)
      const status = await API.post("/updateStatusStoryImages", form)
      setKey(data)

      lodadata()
    }
    if (name === "is_premium") {
      const result = (e === true) ? 1 : 0
      let data = [...key];
      data[index]['is_premium'] = result
      console.log(p)
      const form = new FormData()
      form.append("name", name)
      form.append('staus', (e === true) ? 1 : 0)
      form.append('id', p)
      const is_premiumFunc = await API.post("/updateStatusStoryImages", form)
      console.log(is_premiumFunc);
      setKey(data)
      console.log("here");
      lodadata()

    }

  }


  const removeKey = (index, _id) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger me-3'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = [...key]
        data.splice(index, 1)
        setKey(data)
        const dele = await API.post(`/deletestories/${_id}`)
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {

      }
    })
    // }
  }


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Edit Stories</h3>
      </div>
      <div className="page-content">
        <Form>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                      <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                        <option value="">Select Catgory Id</option>
                        {
                          cat.map((dataVal, ind) => {
                            console.log(dataVal._id, "---id")
                            console.log(user.category_id, "ca_id")
                            return (
                              <option key={ind} value={dataVal._id} selected={dataVal._id === user.category_id._id ? true : false}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Col>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor='m_cate'>Type</Form.Label>
                      <Form.Select className='my-2' name='type' onChange={onInputchange} required>
                        <option>Select Type</option>
                        <option value={0} selected={user.type === 0 ? true : false} >iOS</option>
                        <option value={1} selected={user.type === 1 ? true : false}>Android</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="auto" xs={12}>
                      <Button variant="primary" className='my-3' value="create" onClick={addKey}>Add More Icon</Button>
                    </Col>
                  </Row>
                  <Row>
                    {
                      key.map((keyval, id) => {
                        return (
                          <Row>
                            <Col lg={3} sm={12}>
                              <Form.Control type="hidden" className="my-2" value={keyval._id} name="story_id" />
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Main Image</Form.Label>
                                <InputGroup className="my-2">
                                  <Form.Control type="file" id='cate-img' name='main_image' onChange={(e) => { keyChange(e, id, "main_image") }} />
                                  <img src={keyval.main_image} className='hv-40 bg-light rounded-3' alt="Main Image" />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={3} sm={12}>
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Front Image</Form.Label>
                                <InputGroup className="my-2">
                                  <Form.Control type="file" id='cate-img' name='front_image' onChange={(e) => { keyChange(e, id, "front_image") }} />
                                  <img src={keyval.front_image} className='hv-40 bg-light rounded-3' alt="" />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={3} sm={12}>
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Zip File</Form.Label>
                                <Form.Control type="file" id='cate-img' className="my-2" accept='.zip' name='zip_name' onChange={(e) => { keyChange(e, id, "zip_name") }} />
                              </Form.Group>
                            </Col>
                            <Col lg={1} sm={12}>
                              <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                              {console.log(key.story_id)}
                              <Switch
                                onChange={(e) => { keyChange(e, keyval.story_id, id, "is_premium") }}
                                checked={keyval.is_premium === 1 ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>OFF</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>ON</div>
                                }
                              />
                            </Col>
                            <Col lg={1} sm={12}>
                              <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                              {console.log(key)}
                              <Switch
                                onChange={(e) => { keyChange(e, keyval.story_id, id, "status") }}
                                checked={keyval.status === 1 ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>NO</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>YES</div>
                                }
                              />
                            </Col>
                            <Col lg={1} sm={12} className="align-self-end mb-4">
                              <Button variant="danger" size="sm" className="btn-icon"><i class='bx bx-x' onClick={(e) => { removeKey(id, keyval.story_id) }}></i></Button>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/stories">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default StoriesEdit