import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import { API } from "../../App"
import checkValidation from '../checkValidation';
import Cookies from 'js-cookie'



const MainCatgoryAdd = () => {
  const [image, setimage] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [user, setuser] = useState({
    name: ""
  })
  let navigate = useNavigate();
  const { name } = user

  const onInputchange = (e) => {
    setuser({ ...user, [e.target.name]: e.target.value })
    if (!!errors[e.target.name]) setErrors({
      ...errors,
      [e.target.name]: null
    })

  }

  const handeelsubmission = async () => {
    const newErrors = checkValidation(user)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    }
    else {
      const formData = new FormData();
      formData.append('name', name)
      formData.append('image_field', image)

      setLoading(true)
      const token = Cookies.get('jwt')
      const res = await API.post("/mainCategories", formData, { headers: { "authorization": `Bearer ${token}` } })

      if (res.data.status === true) {
        navigate("/maincatgory")
        toast.success("Add data sucessfully", { position: "bottom-right", autoClose: 500 })
      } else {
        if (res.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error("something wait worng.", { position: "bottom-right", autoClose: 500 })
        }
      }


    }
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Add Main Categories</h3>
        <Row className="align-items-center">
        </Row>
      </div>
      <div className="page-content">
        <Form>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name="name" onChange={onInputchange} placeholder='Enter Name' isInvalid={!!errors.name} />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Image Filed</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setimage(true); console.log(checked, "s-on") } else { setimage(false); console.log(checked, "s-off") } }}
                        checked={image === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handeelsubmission}>Save</Button>
                  <Link to="/maincatgory">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default MainCatgoryAdd