import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import Fancybox from '../../Component/FancyBox';
import { API } from "../../App"
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'


const LivethemeEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [loading, setLoading] = useState(false)
  const [validate, setValidate] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [cat, setCategory] = useState([])

  const lodadata = async () => {
    setLoading(true)
    const token = Cookies.get('jwt')
    const res = await API.post(`/themes/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })

    setuser({
      rank: res.data.data.rank,
      thumb_image: res.data.data.thumb_image,
      name: res.data.data.name,
      btn_Back_Color: res.data.data.btn_Back_Color,
      btn_Key_Color: res.data.data.btn_Key_Color,
      popup_Back_Color: res.data.data.popup_Back_Color,
      popup_Text_Color: res.data.data.popup_Text_Color,
      is_Border: res.data.data.is_Border,
      loop_Mode: res.data.data.loop_Mode,
      json_name: res.data.data.json_name,
      status: res.data.data.status,
      border_Color: res.data.data.border_Color,
      view_Back_Color: res.data.data.view_Back_Color,
      text_Color_For_Show_Key_Off: res.data.data.text_Color_For_Show_Key_Off,
      is_premium: res.data.data.is_premium,
      is_Watch_Video: res.data.data.is_Watch_Video,
      created_at: res.data.data.created_at,
      updated_at: res.data.data.updated_at
    })
    setLoadData(res.data.data)
    console.log(res.data)
    const catRes = await API.post(`/themes`)
    setCategory(catRes.data.data)
    setLoading(false)
  }

  useEffect(() => {
    lodadata()
  }, [])

  const [user, setuser] = useState({
    rank: "",
    name: "",
    btn_Back_Color: "rgb(0,0,0)",
    btn_Key_Color: "rgb(0,0,0)",
    popup_Back_Color: "rgb(0,0,0)",
    popup_Text_Color: "rgb(0,0,0)",
    border_Color: "rgb(0,0,0)",
    view_Back_Color: "rgb(0,0,0)",
    text_Color_For_Show_Key_Off: "rgb(0,0,0)",
    loop_Mode: "",
    thumb_image: "",
    json_name: "",
    status: "",
    is_Border: "",
    is_premium: "",
    is_Watch_Video: ""

  })


  const handdelsubmission = async () => {

    if (user.rank === "" || !user.rank) {
      setValidate(true)
    }
    else if (user.name === "" || !user.name) {
      setValidate(true)
    }
    else if (user.btn_Back_Color === "" || !user.btn_Back_Color) {
      setValidate(true)
    }
    else if (user.btn_Key_Color === "" || !user.btn_Key_Color) {
      setValidate(true)
    }
    else if (user.popup_Back_Color === "" || !user.popup_Back_Color) {
      setValidate(true)
    }
    else if (user.popup_Text_Color === "" || !user.popup_Text_Color) {
      setValidate(true)
    }
    else if (user.border_Color === "" || !user.border_Color) {
      setValidate(true)
    }
    else if (user.view_Back_Color === "" || !user.view_Back_Color) {
      setValidate(true)
    }
    else if (user.text_Color_For_Show_Key_Off === "" || !user.text_Color_For_Show_Key_Off) {
      setValidate(true)
    }
    else if (user.loop_Mode === "" || !user.loop_Mode) {
      setValidate(true)
    }
    else {
      console.log('test')
      const formdata = new FormData()
      formdata.append("rank", user.rank)
      formdata.append("name", user.name)
      formdata.append("loop_Mode", user.loop_Mode)
      formdata.append("btn_Back_Color", user.btn_Back_Color)
      formdata.append("btn_Key_Color", user.btn_Key_Color)
      formdata.append("popup_Back_Color", user.popup_Back_Color)
      formdata.append("popup_Text_Color", user.popup_Text_Color)
      formdata.append("border_Color", user.border_Color)
      formdata.append("view_Back_Color", user.view_Back_Color)
      formdata.append("text_Color_For_Show_Key_Off", user.text_Color_For_Show_Key_Off)
      formdata.append("thumb_image", selectedfile)
      formdata.append("json_name", selectedFile)
      formdata.append("status", user.status)
      formdata.append("is_premium", user.is_premium)
      formdata.append("is_Border", user.is_Border)
      formdata.append("is_Watch_Video", user.is_Watch_Video)

      setLoading(true)
      const token = Cookies.get('jwt')
      const res = await API.post(`/themesupdate/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
      console.log(res, 'res')
      if (res.data.status === true) {
        navigate(`/livetheme/view/${id}`)
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }

  function printColor(color) {
    const r = parseInt(color.substr(1,2), 16)
    const g = parseInt(color.substr(3,2), 16)
    const b = parseInt(color.substr(5,2), 16)
   return "rgb("+r+","+g+","+ b + ")"
  }

  const onInputchange = async (e) => {
    var color = ['text_Color_For_Show_Key_Off','view_Back_Color','border_Color','popup_Text_Color','popup_Back_Color','btn_Key_Color','btn_Back_Color']
    if(color.includes(e.target.name))
    {
      const rgb = await printColor(e.target.value)
      setuser({ ...user, [e.target.name]: rgb })

    }
    else
    {
      setuser({ ...user, [e.target.name]: e.target.value })
    }

  }

  const changeHandler = (event) => {

    setSelectedfile(event.target.files[0]);
    setIsSelected(true);

  }

  const changeHandlers = (event) => {
    console.log(event.target.files[0])
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  }

  const statuschange = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['is_premium']: result
    })
  }


  const statuschanges = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['status']: result
    })
  }

  const borderchange = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['is_Border']: result
    })
  }

  const watchchange = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['is_Watch_Video']: result
    })
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Edit Live Theme</h3>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                        <Form.Control type="number" id='cate-img' name='rank' value={user.rank} className="my-2" onChange={onInputchange} required readOnly />
                        <Form.Control.Feedback type="invalid">
                          Enter Index
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.name} name="name" onChange={onInputchange} required />
                        <Form.Control.Feedback type="invalid">
                          Enter Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >Thumb Image</Form.Label>
                        <InputGroup className="my-2">
                          <Form.Control type="file" id='cate-img'  name='thumb_image' onChange={changeHandler} />
                          <Fancybox>
                            <a data-fancybox="gallery" href={user.thumb_image}>
                              <img src={user.thumb_image} className='hv-40 rounded-3 bg-light' alt="Thumb Image" />
                            </a>
                          </Fancybox>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          Select Thumb Image
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-thumb" >JSON File</Form.Label>
                        <Form.Control type="file" id='cate-thumb' name='json_name' className="my-2" accept='.json' onChange={changeHandlers} />
                        <Form.Control.Feedback type="invalid">
                          Select JSON File
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-bg" >Loop Mode</Form.Label>
                        <Form.Select className='my-2' name='loop_Mode' onClick={onInputchange}>
                          <option value="">Select Loop Mode</option>
                          <option value="1" selected={user.loop_Mode === 1 ? true : false}>For Loop</option>
                          <option value="0" selected={user.loop_Mode === 1 ? true : false}>Auto Reverse</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block my-2'>Status</Form.Label>
                      <Switch
                        onChange={statuschanges}
                        checked={user.status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block my-2'>Is Premium</Form.Label>
                      <Switch
                        onChange={statuschange}
                        checked={user.is_premium === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block my-2'>Is Border</Form.Label>
                      <Switch
                        onChange={borderchange}
                        checked={user.is_Border === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block my-2'>Is Watch Video</Form.Label>
                      <Switch
                        onChange={watchchange}
                        checked={user.is_Watch_Video === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >BTN BackColor</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.btn_Back_Color} className="my-2" name='btn_Back_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.btn_Back_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Button KeyColor</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value="{user.btn_Key_Color}" className="my-2" name='btn_Key_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.btn_Key_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Popup BackColor</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.popup_Back_Color} className="my-2" name='popup_Back_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.popup_Back_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Popup TextColor</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.popup_Text_Color} className="my-2" name='popup_Text_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.popup_Text_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Border Color</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.border_Color} className="my-2" name='border_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.border_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >View BackColor</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.view_Back_Color} className="my-2" name='view_Back_Color' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.view_Back_Color }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >TextColor For ShowKeyOff</Form.Label>
                        <div className='color-box-group'>
                          <Form.Control type="color" value={user.text_Color_For_Show_Key_Off} className="my-2" name='text_Color_For_Show_Key_Off' onChange={onInputchange} />
                          <div className='color-box' style={{ backgroundColor:user.text_Color_For_Show_Key_Off }}></div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission} >Save</Button>
                  <Link to="/livetheme">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>

  )
}

export default LivethemeEdit