import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import checkValidation from '../checkValidation';
import { API } from "../../App"
import Cookies from 'js-cookie'


const MainCatgoryEdit = () => {

  const { id } = useParams()
  let navigate = useNavigate()
  const [LoadData, setLoadData] = useState([])
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [user, setuser] = useState({
    name: ""
  })
  const { name } = user
  const onInputchange = (e) => {
    setuser({ ...user, [e.target.name]: e.target.value })
    if (!!errors[e.target.name]) setErrors({
      ...errors,
      [e.target.name]: null
    })

  }


  const handeelsubmissiom = async () => {
    const newErrors = checkValidation(user)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      const formdata = new FormData()
      formdata.append('name', name)
      formdata.append('image_field', user.image_field)

      setLoading(true)
      const token = Cookies.get('jwt')
      const res = await API.post(`/mainCategoriesUpdate/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
      console.log(res)
      if (res.data.status === true) {
        navigate(`/maincatgory/view/${id}`)
        toast.success("Update data sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }

  const statuschange = (e) => {
    const result = (e === true) ? true : false
    setuser({
      name: user.name,
      image_field: result,
    })
    toast.success("Update Status sucessfully", { position: "bottom-right", autoClose: 500 })

  }

  const loadData = async () => {
    setLoading(true)
    const token = Cookies.get('jwt')
    const res = await API.post(`/mainCategories/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })

    setuser({
      name: res.data.data.name,
      image_field: res.data.data.image_field,
    })
    setLoadData(res.data.data)
    setLoading(false)

  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Edit Main Categories</h3>
        <Row className="align-items-center">
        </Row>
      </div>
      <div className="page-content">
        <Form>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name="name" value={user.name} placeholder='Enter Name' onChange={onInputchange} isInvalid={!!errors.name} />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Image Filed</Form.Label>
                      <Switch
                        onChange={statuschange}
                        checked={user.image_field === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handeelsubmissiom}>Save</Button>
                  <Link to="/maincatgory">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default MainCatgoryEdit