import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { API } from "../../App"
import { toast } from 'react-toastify';
import Switch from "react-switch";
import Cookies from 'js-cookie'
import Fancybox from '../../Component/FancyBox';


const SubCatgoryEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [cat, setCategory] = useState([])


  const lodadata = async () => {
    setLoading(true)
    const token = Cookies.get('jwt')
    const res = await API.post(`/subCategories/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
    console.log(res.data.data.category_id._id)
    setuser({
      rank: res.data.data.rank,
      category_id: res.data.data.category_id._id,
      name: res.data.data.name,
      category_name_sp: res.data.data.category_name_sp,
      category_name_fr: res.data.data.category_name_fr,
      category_name_ku: res.data.data.category_name_ku,
      category_name_hi: res.data.data.category_name_hi,
      category_name_ps: res.data.data.category_name_ps,
      category_name_da: res.data.data.category_name_da,
      category_name_cn: res.data.data.category_name_cn,
      category_name_it: res.data.data.category_name_it,
      category_name_ar: res.data.data.category_name_ar,
      category_name_ja: res.data.data.category_name_ja,
      category_name_ko: res.data.data.category_name_ko,
      category_name_ph: res.data.data.category_name_ph,
      category_name_af: res.data.data.category_name_af,
      category_name_th: res.data.data.category_name_th,
      category_name_ur: res.data.data.category_name_ur,
      category_name_ru: res.data.data.category_name_ru,
      status: res.data.data.status,
      android_status: res.data.data.android_status,
      image: res.data.data.image,
      status: res.data.data.status,
      android_status: res.data.data.android_status
    })
    setLoadData(res.data.data)
    const catRes = await API.post(`/mainCategoriesData`, {}, { headers: { "authorization": `Bearer ${token}` } })
    setCategory(catRes.data.data)
    setLoading(false)
  }

  useEffect(() => {
    lodadata()
  }, [])

  const [user, setuser] = useState({
    rank: "",
    name: "",
    category_name_sp: "",
    category_name_fr: "",
    category_name_ku: "",
    category_name_hi: "",
    category_name_ps: "",
    category_name_da: "",
    category_name_cn: "",
    category_name_it: "",
    category_name_ar: "",
    category_name_ja: "",
    category_name_ko: "",
    category_name_ph: "",
    category_name_af: "",
    category_name_th: "",
    category_name_ur: "",
    category_name_ru: "",
    category_id: "",
    image: "",
    android_status: "",
    status: "",

  })

  const {
    rank,
    name,
    category_name_sp,
    category_name_fr,
    category_name_ku,
    category_name_hi,
    category_name_ps,
    category_name_da,
    category_name_cn,
    category_name_it,
    category_name_ar,
    category_name_ja,
    category_name_ko,
    category_name_ph,
    category_name_af,
    category_name_th,
    category_name_ur,
    category_name_ru,
    category_id,
    status,
    android_status } = user


  const handdelsubmission = async () => {
    if (user.rank === "" || !user.rank) {
      setValidate(true)
    } else if (user.category_id === "" || !user.category_id) {
      setValidate(true)
    }
    else if (user.name === "" || !user.name) {
      setValidate(true)
    }
    else if (user.category_name_sp === "" || !user.category_name_sp) {
      setValidate(true)
    }
    else if (user.category_name_fr === "" || !user.category_name_fr) {
      setValidate(true)
    }
    else if (user.category_name_ku === "" || !user.category_name_ku) {
      setValidate(true)
    }
    else if (user.category_name_hi === "" || !user.category_name_hi) {
      setValidate(true)
    }
    else if (user.category_name_ps === "" || !user.category_name_ps) {
      setValidate(true)
    }
    else if (user.category_name_da === "" || !user.category_name_da) {
      setValidate(true)
    }
    else if (user.category_name_cn === "" || !user.category_name_cn) {
      setValidate(true)
    }
    else if (user.category_name_it === "" || !user.category_name_it) {
      setValidate(true)
    }
    else if (user.category_name_ar === "" || !user.category_name_ar) {
      setValidate(true)
    }
    else if (user.category_name_ja === "" || !user.category_name_ja) {
      setValidate(true)
    }
    else if (user.category_name_ko === "" || !user.category_name_ko) {
      setValidate(true)
    }
    else if (user.category_name_ph === "" || !user.category_name_ph) {
      setValidate(true)
    }
    else if (user.category_name_af === "" || !user.category_name_af) {
      setValidate(true)
    }
    else if (user.category_name_th === "" || !user.category_name_th) {
      setValidate(true)
    }
    else if (user.category_name_ur === "" || !user.category_name_ur) {
      setValidate(true)
    }
    else if (user.category_name_ru === "" || !user.category_name_ru) {
      setValidate(true)
    }
    else {
      console.log(id)
      const formdata = new FormData()
      formdata.append("rank", rank)
      formdata.append("main_cat_id", category_id)
      formdata.append("name", name)
      formdata.append("category_name_sp", category_name_sp)
      formdata.append("category_name_fr", category_name_fr)
      formdata.append("category_name_ku", category_name_ku)
      formdata.append("category_name_hi", category_name_hi)
      formdata.append("category_name_ps", category_name_ps)
      formdata.append("category_name_da", category_name_da)
      formdata.append("category_name_cn", category_name_cn)
      formdata.append("category_name_ar", category_name_ar)
      formdata.append("category_name_it", category_name_it)
      formdata.append("category_name_ja", category_name_ja)
      formdata.append("category_name_ko", category_name_ko)
      formdata.append("category_name_ph", category_name_ph)
      formdata.append("category_name_af", category_name_af)
      formdata.append("category_name_th", category_name_th)
      formdata.append("category_name_ur", category_name_ur)
      formdata.append("category_name_ru", category_name_ru)
      formdata.append("image", selectedfile)
      formdata.append("status", status)
      formdata.append("android_status", android_status)


      const token = Cookies.get('jwt')
      const res = await API.post(`/subCategoriesUpdate/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
      console.log(res)
      if (res.data.status === true) {
        navigate(`/subcatgory/view/${id}`)
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }

  const onInputchange = (e) => {
    console.log(e.target.value)
    setuser({ ...user, [e.target.name]: e.target.value })

  }

  const changeHandler = (event) => {

    setSelectedfile(event.target.files[0]);
    setIsSelected(true);

  }


  const statuschange = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['status']: result
    })
  }


  const andro_status = (e) => {
    const result = (e === true) ? true : false
    setuser({
      ...user, ['android_status']: result
    })
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Edit Sub Categories</h3>
        <Row className="align-items-center">
        </Row>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                        <Form.Control type="number" name='rank' id='cate-img' placeholder='Enter Index' value={user.rank} className="my-2" readOnly />
                      </Form.Group>
                    </Col>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                      <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                        <option value="">Select Category</option>
                        {
                          cat.map((val, id) => {
                            return (
                              <option key={id} selected={val._id === user.category_id ? true : false} value={val._id} >{val.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.name} name='name' onChange={onInputchange} placeholder='Enter Name' required />
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >Image</Form.Label>
                        <InputGroup className="my-2">
                          <Form.Control type="file" id='cate-img' name='image'  onChange={changeHandler} />
                          <Fancybox>
                            <a data-fancybox="gallery" href={user.image}>
                              <img src={user.image} className='hv-40 bg-light rounded-3' alt="Image" />
                            </a>
                          </Fancybox>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Spanish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.category_name_sp} name='category_name_sp' onChange={onInputchange} placeholder='Enter Spanish' required />
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >French</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.category_name_fr} name='category_name_fr' onChange={onInputchange} placeholder='Enter French' required />
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Kurdish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.category_name_ku} name='category_name_ku' onChange={onInputchange} placeholder='Enter Kurdish' required />
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Hindi</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.category_name_hi} name='category_name_hi' onChange={onInputchange} placeholder='Enter Hindi' required />
                      </Form.Group>
                    </Col>

                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Portugal</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" value={user.category_name_ps} name='category_name_ps' onChange={onInputchange} placeholder='Enter Portugal' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Portugal
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Danish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_da' value={user.category_name_da} onChange={onInputchange} placeholder='Enter Danish' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Danish
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Chinese</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_cn' value={user.category_name_cn} onChange={onInputchange} placeholder='Enter Chinese' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Chinese
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Italian</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_it' value={user.category_name_it} onChange={onInputchange} placeholder='Enter Italian' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Italian
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Arabic</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ar' value={user.category_name_ar} onChange={onInputchange} placeholder='Enter Arabic' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Arabic
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Japanese</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ja' value={user.category_name_ja} onChange={onInputchange} placeholder='Enter Japanese' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Japanese
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Korean</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ko' value={user.category_name_ko} onChange={onInputchange} placeholder='Enter Korean' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Korean
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Philippines</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ph' value={user.category_name_ph} onChange={onInputchange} placeholder='Enter Philippines' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Philippines
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >African</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_af' value={user.category_name_af} onChange={onInputchange} placeholder='Enter African' required />
                        <Form.Control.Feedback type="invalid">
                          Enter African
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Thai</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_th' value={user.category_name_th} onChange={onInputchange} placeholder='Enter Thai' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Thai
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Urdu</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ur' value={user.category_name_ur} onChange={onInputchange} placeholder='Enter Urdu' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Urdu
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Russian</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ru' value={user.category_name_ru} onChange={onInputchange} placeholder='Enter Russian' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Russian
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                      <Switch
                        onChange={statuschange}
                        checked={user.status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    {/* <Col lg={3} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Android_Status</Form.Label>
                      <Switch
                        onChange={andro_status}
                        checked={user.android_status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        check edIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col> */}
                    <Col lg={3} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Android Status</Form.Label>
                      <Switch
                        onChange={andro_status}
                        checked={user.android_status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/subcatgory">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default SubCatgoryEdit