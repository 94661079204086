import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import { API } from '../../App';
import Cookies from 'js-cookie'


const WidgetsAdd = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [LoadData, setLoadData] = useState([])
    const [validate, setValidate] = useState(false)
    const [IsSelected, setIsSelected] = useState(false);
    const [selectedfile, setSelectedfile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [cat, setCategory] = useState([])
    const [loading, Setloading] = useState(false)
    const [status, setstatus] = useState(true)
    const [is_premium, setis_premium] = useState(true)
    const [android_status, setandroid_status] = useState(true)
    const [is_premium_android, setis_premium_android] = useState(true)


    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        alignment: "",
        quote: "",
    })

    const handdelsubmission = async () => {

        if (user.rank === "" || !user.rank) {
            setValidate(true)
        } else if (user.alignment === "" || !user.alignment) {
            setValidate(true)
        }
        else if (user.category_id === "" || !user.category_id) {
            setValidate(true)
        }
        else if (user.quote === "" || !user.quote) {
            setValidate(true)
        }
        else if (selectedfile === "" || !selectedfile) {
            setValidate(true)

        }
        else if (selectedFile === "" || !selectedfile) {
            setValidate(true)

        }
        else {
            const formdata = new FormData()
            formdata.append("rank", user.rank)
            formdata.append("category_id", user.category_id)
            formdata.append("alignment", user.alignment)
            formdata.append("quote", user.quote)
            formdata.append("background_image", selectedfile)
            formdata.append("clock_image", selectedFile)
            formdata.append("status", status)
            formdata.append("is_premium", is_premium)
            formdata.append("android_status", android_status)
            formdata.append("is_premium_android", is_premium_android)
            if (key.length === 1) {
                if ((key[0].font_name === "")) {
                    setValidate(true)

                    return
                }
                if ((key[0].color_name === "")) {
                    setValidate(true)

                    return
                }
                formdata.append('font_name', JSON.stringify([key[0].font_name]))
                formdata.append('color_name', JSON.stringify([key[0].color_name]))
            } else {
                key.map((val) => {
                    if ((val.font_name === "")) {
                        setValidate(true)

                        return
                    }
                    if ((val.color_name === "")) {
                        setValidate(true)

                        return
                    }
                    formdata.append('font_name', val.font_name)
                    formdata.append('color_name', val.color_name)
                })
            }
            Setloading(true)
            const token = Cookies.get('jwt')
            const res = await API.post('/widgets', formdata, { headers: { "authorization": `Bearer ${token}` } })
            console.log(res)
            if (res.data.status === true) {
                navigate("/widgets")
                toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
            } else {
                Setloading(false)
            }

        }
    }

    const onInputchange = (e) => {
        console.log(e.target.value)
        setuser({ ...user, [e.target.name]: e.target.value })

    }

    const changeHandler = (event) => {
        console.log(event.target.files[0])
        setSelectedfile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlers = (event) => {
        console.log(event.target.files[0])
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);

    }

    const lodadata = async () => {
        const catRes = await API.post("/widget/category")
        setCategory(catRes.data.data[0].get_SubCategory)

    }
    //key
    const [key, setKey] = useState([{
        font_name: "",
        color_name: ""
    }])
    const addKey = (tag1, name) => {
        const keyTag = {
            font_name: "",
            color_name: ""
        }
        setKey([...key, keyTag])
    }

    const removeKey = (index) => {
        const data = [...key]
        data.splice(index, 1)
        setKey(data)
    }

    const keyChange = (e, index) => {
        let data = [...key];
        data[index][e.target.name] = e.target.value
        setKey(data)
    }

    useEffect(() => {
        lodadata()
    }, [])

    return (
        <Layout sidebar={true}>
            {loading === true ? <div className='loader'></div> : ""}
            <div className="page-heading">
                <h3 className="my-1"> Add Widgets</h3>
            </div>
            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    {
                                        loading ? <div className='loader table-loader' ></div> : <></>
                                    }
                                    <Row>
                                        <Col lg={3} sm={12}>
                                            <Form.Group>
                                                <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                                                <Form.Control type="number" id='cate-img' className="my-2" placeholder='Enter Index' name='rank' onChange={onInputchange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Index
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} xs={12}>
                                            <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                                            <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                                                <option value="">Select Category Id</option>
                                                {
                                                    cat.map((val, id) => {
                                                        return (
                                                            <option key={id} value={val._id} >{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select Category
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group>
                                                <Form.Label htmlFor="cate-img">Background Image</Form.Label>
                                                <Form.Control type="file" id='cate-img' className="my-2" name='background_image' onChange={changeHandler} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Select Background Image
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group>
                                                <Form.Label htmlFor="cate-img">Clock Image</Form.Label>
                                                <Form.Control type="file" id='cate-img' className="my-2" name='clock_image' onChange={changeHandlers} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Select Clock Image
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} xs={12}>
                                            <Form.Label htmlFor='m_cate'>Alignment</Form.Label>
                                            <Form.Select className='my-2' name='alignment' onChange={onInputchange} required>
                                                <option value="">Select Alignment</option>
                                                <option value="right">Right</option>
                                                <option value='center'>Center</option>
                                                <option value='left'>Left</option>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Alignement
                                                </Form.Control.Feedback>
                                            </Form.Select>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group >
                                                <Form.Label htmlFor="cate">Quote</Form.Label>
                                                <Form.Control type="text" id='cate' className="my-2" placeholder='Enter Quote' name='quote' onChange={onInputchange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Quote
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={1} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setis_premium(true); console.log(checked, "s-on") } else { setis_premium(false); console.log(checked, "s-off") } }}
                                                checked={is_premium === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>NO</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col lg={2} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setstatus(true); console.log(checked, "s-on") } else { setstatus(false); console.log(checked, "s-off") } }}
                                                checked={status === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>ON</div>
                                                }
                                            />
                                        </Col>
                                        <Col lg={1} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Android Status</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setandroid_status(true); console.log(checked, "s-on") } else { setandroid_status(false); console.log(checked, "s-off") } }}
                                                checked={android_status === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>ON</div>
                                                }
                                            />
                                        </Col>

                                        <Col lg={2} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium Android</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === true) { setis_premium_android(true); console.log(checked, "s-on") } else { setis_premium_android(false); console.log(checked, "s-off") } }}
                                                checked={is_premium_android === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="auto" xs={12}>
                                            <Button variant="primary" className='my-3' value="create" onClick={addKey}>Add More Icon</Button>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col lg={3} sm={12}>
                                            <Form.Group >
                                                <Form.Label htmlFor="cate">Color Name</Form.Label>
                                                <Form.Control type="text" id='cate' className="my-2" placeholder='Enter Name' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group >
                                                <Form.Label htmlFor="cate">Font Name</Form.Label>
                                                <Form.Control type="text" id='cate' className="my-2" placeholder='Enter Name' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} sm={12} className="align-self-end my-3">
                                            <Button variant="danger" size="sm" className="btn-icon"><i class='bx bx-x' ></i></Button>
                                        </Col>
                                    </Row> */}
                                    {
                                        key.map((keyVal, ind) => {
                                            return (
                                                <Row>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="cate">Color Name</Form.Label>
                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.color_name} name='color_name' onChange={(e) => { keyChange(e, ind) }} placeholder='Enter Color Name' required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Color Name
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="cate">Font Name</Form.Label>
                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.font_name} name='font_name' onChange={(e) => { keyChange(e, ind) }} placeholder='Enter Font Name' required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Font Name
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    {ind !== 0 ? <Col lg={2} sm={12} className="align-self-end my-3">
                                                        <Button variant="danger" size="sm" className="btn-icon" onClick={(e) => { removeKey(ind) }}><i class='bx bx-x' ></i></Button>
                                                    </Col> : ""}
                                                </Row>
                                            )
                                        })
                                    }
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                                    <Link to="/widgets">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default WidgetsAdd