import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Table, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from "../../App"
import Cookies from 'js-cookie'

const DashboradThemeiconView = () => {

    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [category, setCategory] = useState([])
    const [data, setdata] = useState([])
    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        name: "",
        image: "",
        thumbImage: "",
        image_ipad: "",
        is_premium: "",
        status: "",
        thumbimage_ipad: "",
        created_at: "",
        updated_at: ""
    })

    const [LoadData, setLoadData] = useState([])
    let navigate = useNavigate();

    const loadData = async (_id) => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/dashboardThemesData/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(res, "1");
        setLoadData(res.data.data)
        const viewData = await API.post(`/dashboardThemes/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(viewData, "2");
        setCategory(viewData.data.data[0]._id)
        setuser({
            rank: viewData.data.data[0].rank,
            name: viewData.data.data[0].name,
            image: viewData.data.data[0].image,
            thumbImage: viewData.data.data[0].thumb_image,
            is_premium: viewData.data.data[0].is_premium,
            status: viewData.data.data[0].status,
            thumbimage_ipad: viewData.data.data[0].ipad_thumb_image,
            created_at: viewData.data.data[0].created_at,
            updated_at: viewData.data.data[0].updated_at


        })
        setdata(viewData.data.data[0].theme_icons_data)
        Setloading(false)
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)

    }, [])

    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/dashboradtheme" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View DashboradTheme - <span>{user.name}</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1">
                            {
                                LoadData.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id} selected={user.name === dataVal.name ? true : false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/dashboradthemeicon/edit/${category} `}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>

            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>New Index</p> <span>{user.rank}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p> <span>{user.name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.image}>
                                                <img src={user.image} className='hv-40 rounded-2' alt="Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Thumb Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.thumbImage}>
                                                <img src={user.thumbImage} className='hv-40 rounded-2' alt="Thumb Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Thumb Image iPad</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.thumbimage_ipad}>
                                                <img src={user.thumbimage_ipad} className='hv-40 rounded-2' alt="Thumb Image iPad" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p> <span>{user.is_premium === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span>{user.status === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Table bordered responsive id="myTable">
                                <thead>
                                    <tr>
                                        <th width="20%" className='text-center'>Icon</th>
                                        <th width="40%">Icon Name</th>
                                        <th width="40%">Icon Url</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td className='text-center'><img src='https://images.unsplash.com/photo-1548197253-652ffe79752c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80' className='hv-40 bg-light' alt='feedback' /></td>
                                        <td className='text-center'>fontsKeyboard://</td>
                                        <td className='text-center'>fontsKeyboard://</td>
                                    </tr> */}
                                    {
                                        data.map((val, id) => {
                                            return (
                                                <tr key={id}>
                                                    <td className='text-center'>
                                                        <Fancybox>
                                                        <a data-fancybox="gallery" href={val.icon}>
                                                            <img src={val.icon} className='hv-30 bg-light' alt='Icon' />
                                                        </a>
                                                        </Fancybox>
                                                    </td>
                                                    <td>{val.icon_name}</td>
                                                    <td>{val.icon_url}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default DashboradThemeiconView