import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';

const Sidebar = ({ sidebar }) => {
  return (
    <>
      <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className='sidebar-logo'>
              <Link to="/home">
                <Logo />
                <LogoMini />
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-item">
              <NavLink to="/home" className='sidebar-link'>
                <i className='bx bxs-home'></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="sidebar-item">
              <NavLink to="/feedback" className='sidebar-link'>
                <i class='bx bx-message-rounded-dots'></i>
                <span>FeedBack</span>
              </NavLink>
            </li> */}
            <li className="sidebar-item">
              <NavLink to="/livetheme" className='sidebar-link'>
                <i className='bx bxs-brush-alt' ></i>
                <span>Live Theme</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/defaulttheme" className='sidebar-link'>
                <i className='bx bxs-paint-roll' ></i>
                <span>Default Theme</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/maincatgory" className='sidebar-link'>
                <i className='bx bxs-category-alt' ></i>
                <span>Main Categories</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/subcatgory" className='sidebar-link'>
                <i className='bx bxs-collection'></i>
                <span>Sub Categories</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/themeicon" className='sidebar-link'>
                <i className='bx bxs-palette' ></i>
                <span>Theme Icon</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/stories" className='sidebar-link'>
                <i className='bx bxs-video-recording'></i>
                <span>Stories</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/widgets" className='sidebar-link'>
                <i className='bx bxs-widget'></i>
                <span>Widgets</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/uploadata" className='sidebar-link'>
                <i className='bx bxs-cloud-upload' ></i>
                <span>Upload Data</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/dashboradtheme" className='sidebar-link'>
                <i className='bx bxs-brush' ></i>
                <span>Dashborad Theme</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/dashbordwidgets" className='sidebar-link'>
                <i className='bx bxs-dashboard' ></i>
                <span>Dashborad Widgets</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar;