import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import Cookies from 'js-cookie'

const MainCatgoryView = () => {
    const { id } = useParams()
    const [category, setCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const [user, setuser] = useState({
        name: "",
        image_field: false,
        createdAt: "",
        updatedAt: ""
    })
    let navigate = useNavigate();
    const [LoadData, setLoadData] = useState([])
    const loadData = async (_id) => {
        setLoading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/mainCategoriesData/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setLoadData(res.data.data)
        const viewData = await API.post(`/mainCategories/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setCategory(viewData.data.data._id)
        setuser({
            name: viewData.data.data.name,
            image_field: viewData.data.data.image_field,
            createdAt: viewData.data.data.created_at,
            updatedAt: viewData.data.data.updated_at
        })
        console.log({
            name: viewData.data.data.name,
            image_field: viewData.data.data.image_field,
            createdAt: viewData.data.data.createdAt,
            updatedAt: viewData.data.data.updatedAt
        });
        setLoading(false)
    }

    useEffect(() => {
        loadData(id)
    }, []
    )
    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }
    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/maincatgory" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Main Categories - <span>{user.name}</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={(e) => { getCategory(e) }}>
                            {
                                LoadData.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id} selected={user.name === dataVal.name ? true : false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/maincatgory/edit/${category} `}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>

            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={2}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p> <span>{user.name}</span>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Image Filed</p> <span>{user.image_field === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default MainCatgoryView