import React, { useEffect, useState } from 'react'
import { Button, Container, Card, Form } from 'react-bootstrap';
import base64 from "base-64"
import { API } from "../App"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'


let userdata;

const Login = () => {

  let navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);
  const [inpData, setInpData] = useState({
    email: (localStorage.getItem('ejwt')) ? base64.decode(localStorage.getItem('ejwt')) : "",
    password: (localStorage.getItem('pjwt')) ? base64.decode(localStorage.getItem('pjwt')) : "",
    remember: (localStorage.getItem('ejwt') && localStorage.getItem('pjwt')) ? true : false
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }



  const Remember = (e) => {
    setInpData({ email: inpData.email, password: inpData.password, remember: e.target.checked })
  }


  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }

  const tokenAuth = async () => {
    const ejwt = localStorage.getItem('ejwt')
    const pjwt = localStorage.getItem('pjwt')
    if (ejwt && pjwt) {
      const mail = base64.decode(ejwt)
      const pwd = base64.decode(pjwt)
      inpData.email = mail
      inpData.password = pwd
    }

    const token = Cookies.get('jwt')
    const response = await API.post("/homePageApi", {}, { headers: { "authorization": `Bearer ${token}` } })
    if (response.data.response_code === 1) {
      navigate("/home")
    }
  }

  const Logindata = async (event) => {
    event.preventDefault()
    const { email, password } = document.forms[0]
    userdata = {
      email: email.value,
      password: password.value
    }

    const res = await API.post("/login", userdata)
    console.log(res,"logibn")


    if (res.data.response_code === 200) {

      const emailEnc = base64.encode(inpData.email)
      const passEnc = base64.encode(inpData.password)
      if (inpData.remember === true) {
        localStorage.setItem('ejwt', emailEnc)
        localStorage.setItem('pjwt', passEnc)
      } else {
        localStorage.removeItem('ejwt')
        localStorage.removeItem('pjwt')
      }
      toast.success("Login sucesfully", { position: "bottom-right", autoClose: 500 })
      Cookies.set('jwt', res.data.data[0].token,{expires:1})
      navigate("/home")
    }
    else if (res.data.response_code === 0) {
      toast.error("Please email OR Password Valid", { position: "bottom-right", autoClose: 500 })
      navigate("/")
    }
  }

  useEffect(() => {
    tokenAuth()
  })


  return (
    <>
      <Container>
        <div className="auth">
          <div className="auth-box">
            <Card>
              <Card.Header className="pb-0">
                <div className='auth-logo'>
                  <img src="../logo/app-logo.svg" alt="Vasundhara Infotech" className='logo-mini' />
                </div>
              </Card.Header>
              <Card.Body>
                <Form method='post'>
                  <h1 className='auth-title'>Sign in</h1>
                  <h2 className='auth-subtitle'>Fonts-Keyboard</h2>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" className="my-2" name='email' placeholder="Enter Your Email"  value={inpData.email} onChange={(e) => { getValue(e) }}/>
                  </Form.Group>
                  <Form.Group className="mb-4 input-prefix">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type={password} className="my-2" name='password' placeholder="Enter Password" value={inpData.password} onChange={(e) => { getValue(e) }}/>
                    <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" checked={inpData.remember} onClick={(e) => { Remember(e) }} />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100 " onClick={Logindata}>Sign In</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login;