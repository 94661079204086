function checkValidation(data) {
    const newErrors = {}

    // name errors
    if (data.name !== undefined) {
        if (!data.name || data.name === '') newErrors.name = 'Name is Required'
        else if (!isNaN(data.name)) newErrors.name = 'Enter Valid Name'
    }

    // link errors
    if (data.link !== undefined) {
        if (!data.link || data.link === '') newErrors.link = 'Link is Required'
        else if (!isNaN(data.link)) newErrors.link = 'Enter Valid Link'
    }

    if (data.catagory !== undefined) {
        if (!data.catagory || data.catagory === '') newErrors.catagory = 'catagory is Required'
        else if (!isNaN(data.catagory)) newErrors.catagory = 'Enter Valid catagory'
    }

    if (data.author !== undefined) {
        if (!data.author || data.author === '') newErrors.author = 'author is Required'
        else if (!isNaN(data.author)) newErrors.author = 'Enter Valid author'
    }

    if (data.quote !== undefined) {
        if (!data.quote || data.quote === '') newErrors.quote = 'quote is Required'
        else if (!isNaN(data.quote)) newErrors.quote = 'Enter Valid quote'
    }
    
    return newErrors
}

export default checkValidation;