import React from 'react'
import { Button, Container, Card, Form } from 'react-bootstrap';

const Terms = () => {
    return (
        <div className='privacy-policy'>
            {/*Start Hero Section*/}
            <section className="hero-privacy">
                <div className="container text-center">
                    <div className="hero-content">
                        <span>Terms Of Service</span>
                        <h1> Font Arts Keyboard</h1>
                        <p>
                            With the given policy, we notify the laws, rules, limitations,
                            conditions,
                            <br />
                            and terms of services when you use our app.
                        </p>
                    </div>
                </div>
            </section>
            {/*End-Hero-Section*/}
            {/*Start Privacy Policy Section*/}
            <section className="privacy-policy">
                <div className="container">
                    <div className="privacy-policy-content">
                        <div className="mb-30">
                            <h2>1. Terms Of Service</h2>
                            <p>
                                We recognise that privacy is significant. This Terms Of Service
                                applies to all apps (Android) under the publisher name of “Font
                                Arts Keyboard”. We has created this Policy to explain our
                                Terms Of Service practices so you can understand what information
                                about you is collected, used and disclosed. We collect information
                                from you in order to provide corresponding service and better user
                                experience. With your consent of this Privacy Policy, your usage,
                                statistics, input while using our apps would be collected.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>2. Personal Information That We May Collect :</h2>
                            <ul>
                                <li>
                                    Personal Information That We May Collect - We may collect any personal information which specifically identifies
                                    you as an individual and might include your Name and Email Address .
                                    Font Arts Keyboard will utilise your personal information to notify
                                    you about updates on the website, communicate with your regarding
                                    your account or provide offers.
                                </li>
                                <li>
                                    Non Personal Identification Information - We may collect non-personal identification information about Users
                                    whenever they interact with our App. Non-personal identification
                                    information may include the browser name, the type of computer and
                                    technical information about Users means of connection to our App,
                                    such as the operating system and the Internet service providers
                                    utilised and other similar information.
                                </li>
                            </ul>
                        </div>
                        <div className="mb-30">
                            <h2>3. How We Use Collected Information  </h2>
                            <p>
                                We may use your information only for serving you better as below:
                            </p>
                            <ul>
                                <li>To personalise user experience;</li>
                                <li>
                                    To help develop our service- We may use Non- Personal Information to
                                    provide, maintain, improve and develop our services;
                                </li>
                                <li>
                                    We may collect your non-personal information and use them for our
                                    market and promotion purpose, for example, we may promote or
                                    recommend more relevant apps to you.
                                </li>
                                <li>
                                    We use this information to operate, maintain, and provide to you the
                                    features and functionality of our Apps, as well as to communicate
                                    directly with you, such as to send you email messages and push
                                    notifications. We may also send you Service-related emails or
                                    messages (e.g., account verification, order confirmations, change or
                                    updates to features of our apps, technical and security notices). We
                                    use permission like READ_EXTERNAL_STORAGE , WRITE_EXTERNAL_STORAGE,
                                    SET_WALLPAPER.
                                </li>
                            </ul>
                        </div>
                        <div className="mb-30">
                            <h2>4. How Your Information May Be Disclosed</h2>
                            <p>Personal Information. We do not store Personal Information and
                                therefore we do not disclose your Personal Information.</p>

                            <p>Non-Personal Information. We do not combine Non-Personal Information
                                with Personal Information (such as combining your name with your
                                unique User Device number).</p>

                            <p>We not disclose your non-personal information for promotion
                                purpose.</p>
                        </div>
                        <div className="mb-30">
                            <h2>5. How We Protect Your Information</h2>
                            <p>
                                We adopt appropriate data collection, storage and practices and
                                security measures to protect against unauthorised access,
                                alteration, disclosure or destruction of your personal information,
                                username, password, transaction information and data stored on our
                                App. Our App is in compliance with PCI vulnerability standards in
                                order to create as secure of an environment as possible for
                                Users.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>6. Third Party Sites</h2>
                            <p>
                                In general, the Applications, the Services and the Site access third
                                party information (such as your Facebook account information)
                                through application interfaces. We may provide links to third-party
                                Web sites, such as Facebook, as a service to our Users. The Site may
                                also carry advertisements from other companies. When you click on
                                links of third parties in an Application, the Services or the Site,
                                you may leave the Application, the Services or the Site. Some of
                                these third party sites may be co-branded with our name/logo or our
                                affiliated entity's name/logo, even though they are not operated or
                                maintained by us. This Policy does not address, and we are not
                                responsible for, the privacy practices of Web sites operated by
                                third parties, whether they are linked to or otherwise accessible
                                from an Application, the Services or the Site. The inclusion of a
                                link or accessibility of third party Websites does not imply
                                endorsement of such third party Website by us.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>7. Users With Whom You Share Your Information </h2>
                            <p>
                                We cannot control the actions of other Users with whom you share your
                                information. We cannot, and do not, control the information you
                                share with other Users using an Application, the Services or the
                                Site (including via Forums) or how such other Users will use or
                                share such information. We are not responsible for third party
                                circumvention of our security measures.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>8. Security</h2>
                            <p>
                                We are very concerned about safeguarding the confidentiality of your
                                information. We do not collect Personal Information, and we employ
                                administrative, physical and electronic measures designed to protect
                                your Non-Personal Information from unauthorised access and use.
                                Please be aware that no security measures that we take to protect
                                your information is absolutely guaranteed to avoid unauthorised
                                access or use of your Non-Personal Information which is
                                impenetrable.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>9. Sensitive Information</h2>
                            <p>
                                We ask that you not send us, and you not to disclose, any sensitive
                                Personal Information (e.g., information related to racial or ethnic
                                origin, political opinions, religion or other beliefs, health,
                                sexual orientation, criminal background or membership in past
                                organisations, including trade union memberships) on or through an
                                Application, the Services or the Site or otherwise to us.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>10. Advertising</h2>
                            <p>
                                We Ads appearing on our app may be delivered to Users by advertising
                                partners, who may set cookies. These cookies allow the ad server to
                                recognise your computer each time they send you an online
                                advertisement to compile non personal identification information
                                about you or who use your computer. This information allows ad
                                networks to, among other things, deliver targeted advertisements
                                that they believe will be of most interest to you. This privacy
                                policy does not cover the use of cookies by any advertisers. We use
                                Google AdSense and Facebook Audience Network SDK for advertisements
                                in our apps. We don't collect any personally identifiable data.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>11. Google Adsense</h2>
                            <p>
                                Some of the ads may be served by Google. Google's use of the DART
                                cookie enables it to serve ads to Users based ion their visit to our
                                App and other apps on the Internet. DART uses "non personally
                                identifiable information" and does NOT track personal information
                                about you, such as your name, email address, physical address,
                                etc.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>12. Facebook Audience Network </h2>
                            <p>
                                There could be errors in the programming and sometime errors may
                                cause unwanted side effects.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>13. Changes To Terms Of Service</h2>
                            <p>
                                Our Privacy Policy may change from time to time, we will post any
                                privacy policy changes on this page, so please review it
                                periodically. We may provide you additional forms of notice of
                                modifications or updates as appropriate under the circumstances.
                            </p>
                            <p>
                                If you do not agree to any modifications to this Policy, your sole
                                recourse is to immediately stop all use of all Applications, the
                                Services and the Site. Your continued use of any Application, the
                                Services or the Site following the posting of any modifications to
                                this Policy will constitute your acceptance of the revised Policy.
                                Please note that none of our employees or agents has the authority
                                to vary any of our Policies.
                            </p>
                        </div>
                        <div className="mb-30">
                            <h2>14. Contact</h2>
                            <p>
                                If you have any questions about this Terms Of Service, your
                                dealings with below email, please contact us at:
                            </p>
                            <span>Font Arts Keyboard</span>
                            <span>
                                Email :{" "}
                                <a href="mailto:vimal.amazon0000@gmail.com">
                                    vimal.amazon0000@gmail.com
                                </a>
                            </span>
                            <p>Thank You</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*End Privacy Policy Section*/}
        </div>
    )
}

export default Terms;