import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import { API } from '../../App';
import Cookies from 'js-cookie'

const StoriesAdd = () => {
  const navigate = useNavigate()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cat, setCategory] = useState([])
  const [loading, Setloading] = useState(false)
  const [type, settype] = useState(1)

  const [user, setuser] = useState({

    category_id: "",
  })

  const handdelsubmission = async () => {

    if (user.category_id === "" || !user.category_id) {
      setValidate(true)
      console.log('helo2')
    }

    else {
      const formdata = new FormData()

      formdata.append("category_id", user.category_id)
      formdata.append("type", type)

      if (key.length === 1) {
        if (key[0].icon === "") {
          setValidate(true)

          return
        }
        if ((key[0].icon_name === "")) {
          setValidate(true)

          return
        }
        if ((key[0].icon_url === "")) {
          setValidate(true)

          return
        }
        formdata.append('main_image', key[0].main_image)
        formdata.append('front_image', key[0].front_image)
        formdata.append('zip_name', key[0].zip_name)
        formdata.append('status', key[0].status)
        formdata.append('is_premium', key[0].is_premium)
      } else {
        key.map((val) => {
          if ((val.main_image === "")) {
            setValidate(true)

            return
          }
          if ((val.front_image === "")) {
            setValidate(true)

            return
          }
          if ((val.zip_name === "")) {
            setValidate(true)
          }
          formdata.append('main_image', val.main_image)
          formdata.append('front_image', val.front_image)
          formdata.append('zip_name', val.zip_name)
          formdata.append('status', parseInt(val.status))
          formdata.append('is_premium', parseInt(val.is_premium))
        })
      }
      Setloading(true)
      const token = Cookies.get('jwt')
      const res = await API.post('/stories', formdata)
      console.log(res)
      if (res.data.status === true) {
        Setloading(false)
        navigate("/stories")
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }
      else {
        Setloading(false)
      }

    }
  }


  const onInputchange = (e) => {
    console.log(e.target.value)
    setuser({ ...user, [e.target.name]: e.target.value })

  }

  const lodadata = async () => {
    const catRes = await API.post("/Story_category")
    console.log(catRes, "cat");
    setCategory(catRes.data.data[0].get_SubCategory)

  }

  useEffect(() => {
    lodadata()
  }, [])

  //key
  const [key, setKey] = useState([])
  const addKey = (tag1, name) => {
    const keyTag = {
      main_image: null,
      front_image: null,
      zip_name: null,
      is_premium: 1,
      status: 1
    }
    setKey([...key, keyTag])
  }

  const removeKey = (index) => {
    const data = [...key]
    data.splice(index, 1)
    setKey(data)
  }

  const keyChange = (e, index, name) => {
    console.log(name)
    if (name === "main_image") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "front_image") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "zip_name") {
      console.log(e.target.files[0])
      let data = [...key];
      data[index][e.target.name] = e.target.files[0]
      setKey(data)
    }
    if (name === "status") {
      const result = (e === true) ? 1 : 0
      let data = [...key];
      data[index]['status'] = result
      setKey(data)
    }
    if (name === "is_premium") {
      const result = (e === true) ? 1 : 0
      let data = [...key];
      data[index]['is_premium'] = result
      setKey(data)
    }

  }


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Add Stories</h3>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                      <Form.Select className='my-1' name='category_id' onChange={onInputchange} required>
                        <option value="">Select Catgory Id</option>
                        {
                          cat.map((val, id) => {
                            return (
                              <option key={id} value={val._id} >{val.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Catgory
                      </Form.Control.Feedback>
                    </Col>
                    <Col lg={1} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Type</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { settype(1); console.log(checked, "s-on") } else { settype(0); console.log(checked, "s-off") } }}
                        checked={type === 1 ? 1 : 0}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>iOS</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>AOS</div>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="auto" xs={12}>
                      <Button variant="primary" className='my-3' value="create" onClick={addKey}>Add More Icon</Button>
                    </Col>
                  </Row>
                  <Row>
                    {
                      key.map((val, ind) => {
                        return (
                          <Row>
                            <Col lg={3} sm={12}>
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Main Image</Form.Label>
                                <Form.Control type="file" id='cate-img' name='main_image' className="my-2" onChange={(e) => { keyChange(e, ind, "main_image") }} required />
                                <Form.Control.Feedback type="invalid">
                                  Select Main Image
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={3} sm={12}>
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Front Image</Form.Label>
                                <Form.Control type="file" id='cate-img' className="my-2" name='front_image' onChange={(e) => { keyChange(e, ind, "front_image") }} required />
                                <Form.Control.Feedback type="invalid">
                                  Select Front Image
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={3} sm={12}>
                              <Form.Group>
                                <Form.Label htmlFor="cate-img" >Zip File</Form.Label>
                                <Form.Control type="file" id='cate-img' className="my-2" name='zip_name' accept='.zip' onChange={(e) => { keyChange(e, ind, "zip_name") }} required />
                                <Form.Control.Feedback type="invalid">
                                  Select Zip File
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={1} sm={12}>
                              <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                              <Switch
                                onChange={(e) => { keyChange(e, ind, "is_premium") }}
                                checked={val.is_premium === 1 ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>OFF</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>ON</div>
                                }
                              />
                            </Col>
                            <Col lg={1} sm={12}>
                              <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                              <Switch
                                onChange={(e) => { keyChange(e, ind, "status") }}
                                checked={val.status === 1 ? true : false}
                                offColor="#C8C8C8"
                                onColor="#0093ed"
                                height={30}
                                width={70}
                                className="react-switch"
                                uncheckedIcon={
                                  <div className='react-switch-off'>NO</div>
                                }
                                checkedIcon={
                                  <div className='react-switch-on'>YES</div>
                                }
                              />
                            </Col>
                            <Col lg={1} sm={12} className="align-self-end mb-4">
                              <Button variant="danger" size="sm" className="btn-icon" onClick={(e) => { removeKey(ind) }}> <i class='bx bx-x' ></i></Button>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/stories">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default StoriesAdd