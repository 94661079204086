import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Button, Table, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
// import Fancybox from '../../Component/FancyBox';
import Switch from "react-switch";
import Cookies from 'js-cookie'

const FeedBack = () => {
    const [iconcoror, Seticoncoror] = useState("name_up")
    return (

        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Feedback</h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1'>
                            <option value="">Select Is Status</option>
                            <option value="1">ON</option>
                            <option value="0">OFF</option>
                        </Form.Select>
                    </Col>
                    {/* <Col md="auto" xs={12}>
                        <Link to="/feedback/add">
                            <Button variant="primary" className='my-1' value="create">Add New</Button>
                        </Link>
                    </Col> */}
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Table bordered responsive id="myTable">
                                    <thead>
                                        <tr>
                                            <th width="3%" className='text-center'>No</th>
                                            <th width="15%">
                                            <div className='table-sort-filter'>
                                                Review
                                                <span className='table-sort'>
                                                <div className={`sort-down ${iconcoror === "name_up" ? "active" : ""}`} id="name_up"  ></div>
                                                <div className={`sort-up ${iconcoror === "name_down" ? "active" : ""}`} id="name_down"></div>
                                                    </span>
                                                    </div>
                                                </th>
                                            <th width="5%" className='text-center'>
                                            <div className='table-sort-filter'> 
                                                Rating
                                                <span className='table-sort'>
                                                <div className={`sort-down ${iconcoror === "" ? "active" : ""}`} id="name_up"  ></div>
                                                <div className={`sort-up ${iconcoror === "" ? "active" : ""}`} id="name_down"></div>
                                                    </span>
                                                    </div>
                                                </th>
                                            <th width="13%" className='text-center'>
                                            <div className='table-sort-filter'> 
                                                Contact Information
                                                <span className='table-sort'>
                                                <div className={`sort-down ${iconcoror === "" ? "active" : ""}`} id="name_up"  ></div>
                                                <div className={`sort-up ${iconcoror === "" ? "active" : ""}`} id="name_down"></div>
                                                    </span>
                                                    </div>
                                                </th>
                                            <th width="8%" className='text-center'>Version Code</th>
                                            <th width="5%" className='text-center'>Version Name</th>
                                            <th width="8%" className='text-center'>Image</th>
                                            <th width="8%" className='text-center'>
                                            <div className='table-sort-filter'>  
                                                Status <span className='table-sort'>
                                                <div className={`sort-down ${iconcoror === "" ? "active" : ""}`} id="name_up"  ></div>
                                                <div className={`sort-up ${iconcoror === "" ? "active" : ""}`} id="name_down"></div>
                                                    </span>
                                                    </div>
                                                </th> 
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>NO PROBLEM</td>
                                            <td>excellent</td>
                                            <td>leannemellornew@gmail.com</td>
                                            <td>2.6 - Live</td>
                                            <td>Ios</td>
                                            <td><img src='https://images.unsplash.com/photo-1548197253-652ffe79752c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80' className='hv-40 bg-light' alt='feedback' /></td>
                                            <td>
                                                <Switch
                                                    checked={true}
                                                    offColor="#C8C8C8"
                                                    onColor="#0093ed"
                                                    height={30}
                                                    width={70}
                                                    className="react-switch"
                                                    uncheckedIcon={
                                                        <div className='react-switch-off'>OFF</div>
                                                    }
                                                    checkedIcon={
                                                        <div className='react-switch-on'>ON</div>
                                                    }
                                                />
                                            </td>
                                            <td className='text-center'>
                                                {/* <Link to={"/feedback/view"}>
                                                    <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                </Link> */}
                                                <Link to="/feedback">
                                                    <Button variant="outline-danger" size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default FeedBack