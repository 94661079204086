import React from "react";

const Error = () => {
    return (
        <>
            <img src="../../not-found/category.svg" style={{ width: "50%", height: "50vh", marginTop: "100px", marginLeft: "500px" }} alt="" />
            <h1 style={{ marginLeft: "800px" }}>No Data Found</h1>
        </>
    )
}

export default Error;