
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie'

const SubCatgoryView = () => {
    const { id } = useParams()
    const [loading, Setloading] = useState(true)
    const [category, setCategory] = useState([])
    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        name: "",
        category_name_sp: "",
        category_name_fr: "",
        category_name_ku: "",
        category_name_hi: "",
        category_name_ps: "",
        category_name_da: "",
        category_name_cn: "",
        category_name_it: "",
        category_name_ar: "",
        category_name_ja: "",
        category_name_ko: "",
        category_name_ph: "",
        category_name_af: "",
        category_name_th: "",
        category_name_ur: "",
        category_name_ru: "",
        image: "",
        status: "",
        android_status: "",
        created_at: "",
        updated_at: ""
    })
    const [LoadData, setLoadData] = useState([])

    const loadData = async (_id) => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/subCategoriesData/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setLoadData(res.data.data)
        const viewData = await API.post(`/subCategories/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setCategory(viewData.data.data._id)
        setuser({
            rank: viewData.data.data.rank,
            category_id: viewData.data.data.category_id,
            name: viewData.data.data.name,
            category_name_sp: viewData.data.data.category_name_sp,
            category_name_fr: viewData.data.data.category_name_fr,
            category_name_ku: viewData.data.data.category_name_ku,
            category_name_hi: viewData.data.data.category_name_hi,

            category_name_ps: viewData.data.data.category_name_ps,
            category_name_da: viewData.data.data.category_name_da,
            category_name_cn: viewData.data.data.category_name_cn,
            category_name_it: viewData.data.data.category_name_it,
            category_name_ar: viewData.data.data.category_name_ar,
            category_name_ja: viewData.data.data.category_name_ja,
            category_name_ko: viewData.data.data.category_name_ko,
            category_name_ph: viewData.data.data.category_name_ph,
            category_name_af: viewData.data.data.category_name_af,
            category_name_th: viewData.data.data.category_name_th,
            category_name_ur: viewData.data.data.category_name_ur,
            category_name_ru: viewData.data.data.category_name_ru,

            image: viewData.data.data.image,
            status: viewData.data.data.status,
            android_status: viewData.data.data.android_status,
            created_at: viewData.data.data.created_at,
            updated_at: viewData.data.data.updated_at


        })
        Setloading(false)
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)

    }, [])

    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/subcatgory" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Sub Categories - <span>{user.name}</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={(e) => { getCategory(e) }}>
                            {
                                LoadData.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id} selected={user.name === dataVal.name ? true : false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/subcatgory/edit/${category} `}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>New Index</p> <span>{user.rank}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Category Id</p> <span>{user.category_id.name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Spanish </p> <span>{user.category_name_sp}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>French </p> <span>{user.category_name_fr}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Kurdish</p> <span>{user.category_name_ku}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Hindi </p> <span>{user.category_name_hi}</span>
                                    </div>
                                </Col>

                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Portugal </p> <span>{user.category_name_ps}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Danish </p> <span>{user.category_name_da}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Chinese</p> <span>{user.category_name_cn}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Italian </p> <span>{user.category_name_it}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Arabic </p> <span>{user.category_name_ar}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Japanese </p> <span>{user.category_name_ja}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Korean</p> <span>{user.category_name_ko}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Philippines </p> <span>{user.category_name_ph}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>African </p> <span>{user.category_name_af}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Thai </p> <span>{user.category_name_th}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Urdu</p> <span>{user.category_name_ur}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Russian </p> <span>{user.category_name_ru}</span>
                                    </div>
                                </Col>

                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p> <span>{user.name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.image}>
                                                <img src={user.image} className='hv-40 rounded-3' alt="Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span>{user.status === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Android Status</p> <span>{user.android_status === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default SubCatgoryView