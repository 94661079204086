import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { API } from "../../App"
import { toast } from 'react-toastify';
import Switch from "react-switch";
import Cookies from 'js-cookie'



const SubCatgoryAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [cat, setCategory] = useState([])
  const [loading, Setloading] = useState(false)
  const [status, setstatus] = useState(true)
  const [android_status, setandroid_status] = useState(true)

  const [user, setuser] = useState({
    rank: "",
    name: "",
    category_name_sp: "",
    category_name_fr: "",
    category_name_ku: "",
    category_name_hi: "",
    category_name_ps: "",
    category_name_da: "",
    category_name_cn: "",
    category_name_it: "",
    category_name_ar: "",
    category_name_ja: "",
    category_name_ko: "",
    category_name_ph: "",
    category_name_af: "",
    category_name_th: "",
    category_name_ur: "",
    category_name_ru: "",
    category_id: "",
  })

  const {
    rank,
    name,
    category_name_sp,
    category_name_fr,
    category_name_ku,
    category_name_hi,
    category_name_ps,
    category_name_da,
    category_name_cn,
    category_name_it,
    category_name_ar,
    category_name_ja,
    category_name_ko,
    category_name_ph,
    category_name_af,
    category_name_th,
    category_name_ur,                     
    category_name_ru,
    category_id } = user


  const handdelsubmission = async () => {

    if (user.rank === "" || !user.rank) {
      setValidate(true)
    } else if (user.category_id === "" || !user.category_id) {
      setValidate(true)
    }
    else if (user.name === "" || !user.name) {
      setValidate(true)
    }
    else if (user.category_name_sp === "" || !user.category_name_sp) {
      setValidate(true)
    }
    else if (user.category_name_fr === "" || !user.category_name_fr) {
      setValidate(true)
    }
    else if (user.category_name_ku === "" || !user.category_name_ku) {
      setValidate(true)
    }
    else if (user.category_name_hi === "" || !user.category_name_hi) {
      setValidate(true)
    }


    else if (user.category_name_ps === "" || !user.category_name_ps) {
      setValidate(true)
    }
    else if (user.category_name_da === "" || !user.category_name_da) {
      setValidate(true)
    }
    else if (user.category_name_cn === "" || !user.category_name_cn) {
      setValidate(true)
    }
    else if (user.category_name_it === "" || !user.category_name_it) {
      setValidate(true)
    }
    else if (user.category_name_ar === "" || !user.category_name_ar) {
      setValidate(true)
    }
    else if (user.category_name_ja === "" || !user.category_name_ja) {
      setValidate(true)
    }
    else if (user.category_name_ko === "" || !user.category_name_ko) {
      setValidate(true)
    }
    else if (user.category_name_ph === "" || !user.category_name_ph) {
      setValidate(true)
    }
    else if (user.category_name_af === "" || !user.category_name_af) {
      setValidate(true)
    }
    else if (user.category_name_th === "" || !user.category_name_th) {
      setValidate(true)
    }
    else if (user.category_name_ur === "" || !user.category_name_ur) {
      setValidate(true)
    }
    else if (user.category_name_ru === "" || !user.category_name_ru) {
      setValidate(true)
    }
    else if (selectedfile === "" || !selectedfile) {
      setValidate(true)
    }
    else {
      const formdata = new FormData()
      formdata.append("rank", rank)
      formdata.append("main_cat_id", category_id)
      formdata.append("name", name)
      formdata.append("category_name_sp", category_name_sp)
      formdata.append("category_name_fr", category_name_fr)
      formdata.append("category_name_ku", category_name_ku)
      formdata.append("category_name_hi", category_name_hi)
      formdata.append("category_name_ps", category_name_ps)
      formdata.append("category_name_da", category_name_da)
      formdata.append("category_name_cn", category_name_cn)
      formdata.append("category_name_ar", category_name_ar)
      formdata.append("category_name_it", category_name_it)
      formdata.append("category_name_ja", category_name_ja)
      formdata.append("category_name_ko", category_name_ko)
      formdata.append("category_name_ph", category_name_ph)
      formdata.append("category_name_af", category_name_af)
      formdata.append("category_name_th", category_name_th)
      formdata.append("category_name_ur", category_name_ur)
      formdata.append("category_name_ru", category_name_ru)        
      formdata.append("image", selectedfile)
      formdata.append("status", status)
      formdata.append("android_status", android_status)

      Setloading(true)
      const token = Cookies.get('jwt')
      const res = await API.post('/subCategories', formdata, { headers: { "authorization": `Bearer ${token}` } })
      if (res.data.status === true) {
        navigate("/subcatgory")
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }

  const onInputchange = (e) => {
    console.log(e.target.value)
    setuser({ ...user, [e.target.name]: e.target.value })

  }

  const changeHandler = (event) => {

    setSelectedfile(event.target.files[0]);
    setIsSelected(true);

  }
  const lodadata = async () => {
    const token = Cookies.get('jwt')
    const catRes = await API.post("/mainCategoriesData", {}, { headers: { "authorization": `Bearer ${token}` } })
    setCategory(catRes.data.data)

  }


  useEffect(() => {
    lodadata()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Add Sub Categories</h3>
        <Row className="align-items-center">
        </Row>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                        <Form.Control type="number" name='rank' id='cate-img' placeholder='Enter Index' onChange={onInputchange} className="my-2" required />
                        <Form.Control.Feedback type="invalid">
                          Enter Index
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                      <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                        <option value="">Select Category</option>
                        {
                          cat.map((val, id) => {
                            return (
                              <option key={id} value={val._id} >{val.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Category
                      </Form.Control.Feedback>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' name='name' className="my-2" onChange={onInputchange} placeholder='Enter Name' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >Image</Form.Label>
                          <Form.Control type="file" id='cate-img' name='image' className="my-2" onChange={changeHandler} required />
                          <Form.Control.Feedback type="invalid">
                            Select Image
                          </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Spanish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_sp' onChange={onInputchange} placeholder='Enter Spanish' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Spanish
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >French</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_fr' onChange={onInputchange} placeholder='Enter French' required />
                        <Form.Control.Feedback type="invalid">
                          Enter French
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Kurdish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ku' onChange={onInputchange} placeholder='Enter Kurdish' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Kurdish
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Hindi</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_hi' onChange={onInputchange} placeholder='Enter Hindi' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Hindi
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Portugal</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ps' onChange={onInputchange} placeholder='Enter Portugal' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Portugal
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Danish</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_da' onChange={onInputchange} placeholder='Enter Danish' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Danish
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Chinese</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_cn' onChange={onInputchange} placeholder='Enter Chinese' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Chinese
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Italian</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_it' onChange={onInputchange} placeholder='Enter Italian' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Italian
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Arabic</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ar' onChange={onInputchange} placeholder='Enter Arabic' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Arabic
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Japanese</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ja' onChange={onInputchange} placeholder='Enter Japanese' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Japanese
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Korean</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ko' onChange={onInputchange} placeholder='Enter Korean' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Korean
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Philippines</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ph' onChange={onInputchange} placeholder='Enter Philippines' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Philippines
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >African</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_af' onChange={onInputchange} placeholder='Enter African' required />
                        <Form.Control.Feedback type="invalid">
                          Enter African
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Thai</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_th' onChange={onInputchange} placeholder='Enter Thai' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Thai
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Urdu</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ur' onChange={onInputchange} placeholder='Enter Urdu' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Urdu
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Russian</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name='category_name_ru' onChange={onInputchange} placeholder='Enter Russian' required />
                        <Form.Control.Feedback type="invalid">
                          Enter Russian
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setstatus(true); console.log(checked, "s-on") } else { setstatus(false); console.log(checked, "s-off") } }}
                        checked={status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Android Status</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setandroid_status(true); console.log(checked, "s-on") } else { setandroid_status(false); console.log(checked, "s-off") } }}
                        checked={android_status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/subcatgory">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default SubCatgoryAdd