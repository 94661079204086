import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { API } from "../../App"
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'



const LivethemeAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [LoadData, setLoadData] = useState([])
  const [validate, setValidate] = useState(false)
  const [IsSelected, setIsSelected] = useState(false);
  const [selectedfile, setSelectedfile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false)
  const [status, setstatus] = useState(true)
  const [is_Border, setis_Border] = useState(true)
  const [is_Watch_Video, setis_Watch_Video] = useState(true)
  const [is_premium, setis_premium] = useState(true)

  const [user, setuser] = useState({
    rank: "",
    name: "",
    btn_Back_Color: "rgb(0,0,0)",
    btn_Key_Color: "rgb(0,0,0)",
    popup_Back_Color: "rgb(0,0,0)",
    popup_Text_Color: "rgb(0,0,0)",
    border_Color: "rgb(0,0,0)",
    view_Back_Color: "rgb(0,0,0)",
    text_Color_For_Show_Key_Off: "rgb(0,0,0)",
    loop_Mode: ""

  })



  const handdelsubmission = async () => {
    console.log('helo')
    if (user.rank === "" || !user.rank) {
      setValidate(true)
    }
    else if (user.name === "" || !user.name) {
      setValidate(true)
    }
    else if (user.btn_Back_Color === "" || !user.btn_Back_Color) {
      setValidate(true)
    }
    else if (user.btn_Key_Color === "" || !user.btn_Key_Color) {
      setValidate(true)
    }
    else if (user.popup_Back_Color === "" || !user.popup_Back_Color) {
      setValidate(true)
    }
    else if (user.popup_Text_Color === "" || !user.popup_Text_Color) {
      setValidate(true)
    }
    else if (user.border_Color === "" || !user.border_Color) {
      setValidate(true)
    }
    else if (user.view_Back_Color === "" || !user.view_Back_Color) {
      setValidate(true)
    }
    else if (user.text_Color_For_Show_Key_Off === "" || !user.text_Color_For_Show_Key_Off) {
      setValidate(true)
    }
    else if (user.loop_Mode === "" || !user.loop_Mode) {
      setValidate(true)
    }
    else if (selectedfile === "" || !selectedfile) {
      setValidate(true)

    }
    else if (selectedFile === "" || !selectedFile) {
      setValidate(true)

    }
    else {
      console.log('test')
      const formdata = new FormData()
      formdata.append("rank", user.rank)
      formdata.append("name", user.name)
      formdata.append("loop_Mode", user.loop_Mode)
      formdata.append("btn_Back_Color", user.btn_Back_Color)
      formdata.append("btn_Key_Color", user.btn_Key_Color)
      formdata.append("popup_Back_Color", user.popup_Back_Color)
      formdata.append("popup_Text_Color", user.popup_Text_Color)
      formdata.append("border_Color", user.border_Color)
      formdata.append("view_Back_Color", user.view_Back_Color)
      formdata.append("text_Color_For_Show_Key_Off", user.text_Color_For_Show_Key_Off)
      formdata.append("thumb_image", selectedfile)
      formdata.append("json_name", selectedFile)
      formdata.append("status", status)
      formdata.append("is_premium", is_premium)
      formdata.append("is_Border", is_Border)
      formdata.append("is_Watch_Video", is_Watch_Video)

      setLoading(true)
      const token = Cookies.get('jwt')
      const res = await API.post('/themes', formdata, { headers: { "authorization": `Bearer ${token}` } })
      console.log(res, 'res')
      if (res.data.status === true) {
        navigate("/livetheme")
        toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
      }

    }
  }

  function printColor(color) {
    const r = parseInt(color.substr(1,2), 16)
    const g = parseInt(color.substr(3,2), 16)
    const b = parseInt(color.substr(5,2), 16)
   return "rgb("+r+","+g+","+ b + ")"
  }

  const onInputchange = async (e) => {
    var color = ['text_Color_For_Show_Key_Off','view_Back_Color','border_Color','popup_Text_Color','popup_Back_Color','btn_Key_Color','btn_Back_Color']
    if(color.includes(e.target.name))
    {
      const rgb = await printColor(e.target.value)
      setuser({ ...user, [e.target.name]: rgb })
    }
    else
    {
      setuser({ ...user, [e.target.name]: e.target.value })
    }
  }

  const changeHandler = (event) => {

    setSelectedfile(event.target.files[0]);
    setIsSelected(true);

  }

  const changeHandlers = (event) => {

    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  }


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1"> Add Live Theme</h3>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  {
                    loading ? <div className='loader table-loader' ></div> : <></>
                  }
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                        <Form.Control type="number" id='cate-img' name='rank' className="my-2" onChange={onInputchange} required />
                        <Form.Control.Feedback type="invalid">
                          Enter Index
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Name</Form.Label>
                        <Form.Control type="text" id='cate' className="my-2" name="name" onChange={onInputchange} required />
                        <Form.Control.Feedback type="invalid">
                          Enter Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >Thumb Image</Form.Label>
                        <Form.Control type="file" id='cate-img' name='thumb_image' className="my-2" onChange={changeHandler} required />
                        <Form.Control.Feedback type="invalid">
                          Select Thumb Image
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group>
                        <Form.Label htmlFor="cate-img" >JSON File</Form.Label>
                        <Form.Control type="file" id='cate-img' name='json_name' className="my-2" accept='.json' onChange={changeHandlers} required />
                        <Form.Control.Feedback type="invalid">
                          Select JSON File
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm="12" className='mb-2'>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Status</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setstatus(true); console.log(checked, "s-on") } else { setstatus(false); console.log(checked, "s-off") } }}
                        checked={status === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12" className='mb-2'>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setis_premium(true); console.log(checked, "s-on") } else { setis_premium(false); console.log(checked, "s-off") } }}
                        checked={is_premium === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12" className='mb-2'>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Border</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setis_Border(true); console.log(checked, "s-on") } else { setis_Border(false); console.log(checked, "s-off") } }}
                        checked={is_Border === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>OFF</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>ON</div>
                        }
                      />
                    </Col>
                    <Col lg={3} sm="12" className='mb-2'>
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Watch Video</Form.Label>
                      <Switch
                        onChange={(checked) => { if (checked === true) { setis_Watch_Video(true); console.log(checked, "s-on") } else { setis_Watch_Video(false); console.log(checked, "s-off") } }}
                        checked={is_Watch_Video === true ? true : false}
                        offColor="#C8C8C8"
                        onColor="#0093ed"
                        height={30}
                        width={70}
                        className="react-switch"
                        uncheckedIcon={
                          <div className='react-switch-off'>NO</div>
                        }
                        checkedIcon={
                          <div className='react-switch-on'>YES</div>
                        }
                      />
                    </Col>
                    <Col lg={3} xs={12}>
                      <Form.Label htmlFor="media_type">Loop Mode</Form.Label>
                      <Form.Select className='my-2' name='loop_Mode' onChange={onInputchange}>
                        <option value="">Select Loop Mode</option>
                        <option value={1}>For Loop</option>
                        <option value={0}>Auto Reverse</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >BTN BackColor</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='btn_Back_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Button KeyColor</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='btn_Key_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Popup BackColor</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='popup_Back_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Popup TextColor</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='popup_Text_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >Border Color</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='border_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >View BackColor</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='view_Back_Color' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor="cate" >TextColor For ShowKeyOff</Form.Label>
                        <div className='color-boxs'>
                          <Form.Control type="color" name='text_Color_For_Show_Key_Off' className="my-2" onChange={onInputchange} />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                  <Link to="/livetheme">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>

  )
}

export default LivethemeAdd