import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from "../../App"
import Cookies from 'js-cookie'


const DashboradWidgetsView = () => {
    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [category, setCategory] = useState([])
    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        background_image: "",
        alignment: "",
        clock_image: "",
        quote: "",
        is_premium: "",
        status: "",
        created_at: "",
        updated_at: ""
    })
    const [LoadData, setLoadData] = useState([])
    let navigate = useNavigate();

    const loadData = async (_id) => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/dashboardWidgetsData/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(res, "1");
        setLoadData(res.data.data)
        const viewData = await API.post(`/dashboardWidgets/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(viewData, "2");
        setCategory(viewData.data.data[0]._id)
        setuser({
            rank: viewData.data.data[0].rank,
            category_id: viewData.data.data[0].category_id.name,
            background_image: viewData.data.data[0].background_image,
            alignment: viewData.data.data[0].alignment,
            clock_image: viewData.data.data[0].clock_image,
            quote: viewData.data.data[0].quote,
            is_premium: viewData.data.data[0].is_premium,
            image: viewData.data.data[0].image,
            status: viewData.data.data[0].status,
            created_at: viewData.data.data[0].created_at,
            updated_at: viewData.data.data[0].updated_at


        })
        Setloading(false)
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)

    }, [])
    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/dashbordwidgets" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Dashborad Widgets - <span>{user.category_id}</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Link to={`/dashbordwidgets/edit/${category}`}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>New Index</p> <span>{user.rank}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Category Id</p> <span>{user.category_id}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Background Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.background_image}>
                                                <img src={user.background_image} className='hv-40 rounded-3' alt="Background Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Clock Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.clock_image}>
                                                <img src={user.clock_image} className='hv-40 rounded-3' alt="Clock Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span>{user.status === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p> <span>{user.is_premium === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Created At</p> <span>{user.created_at}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Updated At</p> <span>{user.updated_at}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default DashboradWidgetsView