import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.css';
import './App.css';
import './utilities.css';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import Login from './pages/Login';

import Livetheme from './pages/LiveTheme/Livetheme';
import LivethemeAdd from './pages/LiveTheme/LivethemeAdd';
import LivethemeView from './pages/LiveTheme/LivethemeView';
import LivethemeEdit from './pages/LiveTheme/LivethemeEdit';

import DefaultTheme from './pages/DefaultTheme/DefaultTheme ';
import DefultThemeAdd from './pages/DefaultTheme/DefaultThemeAdd';
import DefultThemeEdit from './pages/DefaultTheme/DefaultThemeEdit';
import DefultThemeView from './pages/DefaultTheme/DefultThemeView';

import MainCatgory from './pages/MainCatgory/MainCatgory';
import MainCatgoryAdd from './pages/MainCatgory/MainCatgoryAdd';
import MainCatgoryView from './pages/MainCatgory/MainCatgoryView';
import MainCatgoryEdit from './pages/MainCatgory/MainCatgoryEdit';

import SubCatgory from './pages/Sub Catgory/SubCatgory';
import SubCatgoryAdd from './pages/Sub Catgory/SubCatgoryAdd';
import SubCatgoryView from './pages/Sub Catgory/SubCatgoryView';
import SubCatgoryEdit from './pages/Sub Catgory/SubCatgoryEdit';

import Themeicon from './pages/ThemeIcon/Themeicon';
import ThemeiconAdd from './pages/ThemeIcon/ThemeiconAdd';
import ThemeiconView from './pages/ThemeIcon/ThemeiconView';
import ThemeIconEdit from './pages/ThemeIcon/ThemeiconEdit';

import Widgets from './pages/Widgets/Widgets';
import WidgetsAdd from './pages/Widgets/WidgetsAdd';
import WidgetsView from './pages/Widgets/WidgetsView';
import WidgetsEdit from './pages/Widgets/WidgetsEdit';

import UploadData from './pages/UploadData/Uplodadata';
import UploadDataAdd from './pages/UploadData/UploadDataAdd';
import UploadDataView from './pages/UploadData/UploadDataView';
import UploaddataEdit from './pages/UploadData/UploadDataEdit';

import DashbordWidgets from './pages/DashbordWidgets/DashbordWidgets';
import DashboradWidgetsAdd from './pages/DashbordWidgets/DashbordWidgetsAdd';
import DashboradWidgetsView from './pages/DashbordWidgets/DashbordWidgetsView';
import DashboradWidgetsEdit from './pages/DashbordWidgets/DashbordWidgetsEdit';

import DashboradThemeicon from './pages/DashbordTheme/DashbordTheme';
import DashboradThemeiconAdd from './pages/DashbordTheme/DashboradThemeAdd';
import DashboradThemeiconView from './pages/DashbordTheme/DashboradThemeView';
import DashboradThemeiconEdit from './pages/DashbordTheme/DashboradThemeEdit';

import Stories from './pages/Stories/Stories';
import StoriesAdd from './pages/Stories/StoriesAdd';
import StoriesView from './pages/Stories/StoriesView';
import StoriesEdit from './pages/Stories/StoriesEdit';

import FeedBack from './pages/Feedback/Feedback';
import Error from './pages/Error/Error';
import Terms from './pages/Terms/Terms';
import axios from 'axios'
export const API = axios.create({ baseURL: process.env.REACT_APP_API_LINK })


function App() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <BrowserRouter>
        {/* <HashRouter> */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />

          <Route path='/livetheme' element={<Livetheme />} />
          <Route path='/livetheme/add' element={<LivethemeAdd />} />
          <Route path='/livetheme/view/:id' element={<LivethemeView />} />
          <Route path='/livetheme/edit/:id' element={<LivethemeEdit />} />

          <Route path='/defaulttheme' element={<DefaultTheme />} />
          <Route path='/defaulttheme/add' element={<DefultThemeAdd />} />
          <Route path='/defaulttheme/edit/:id' element={<DefultThemeEdit />} />
          <Route path='/defaulttheme/view/:id' element={<DefultThemeView />} />

          <Route path='/maincatgory' element={<MainCatgory />} />
          <Route path='/maincatgory/add' element={<MainCatgoryAdd />} />
          <Route path='/maincatgory/view/:id' element={<MainCatgoryView />} />
          <Route path='/maincatgory/edit/:id' element={<MainCatgoryEdit />} />

          <Route path='/subcatgory' element={<SubCatgory />} />
          <Route path='/subcatgory/add' element={<SubCatgoryAdd />} />
          <Route path='/subcatgory/view/:id' element={<SubCatgoryView />} />
          <Route path='/subcatgory/edit/:id' element={<SubCatgoryEdit />} />

          <Route path='/themeicon' element={<Themeicon />} />
          <Route path='/themeicon/add' element={<ThemeiconAdd />} />
          <Route path='/themeicon/view/:id' element={<ThemeiconView />} />
          <Route path='/themeicon/edit/:id' element={<ThemeIconEdit />} />

          <Route path='/widgets' element={<Widgets />} />
          <Route path='/widgets/add' element={<WidgetsAdd />} />
          <Route path='/widgets/view/:id' element={<WidgetsView />} />
          <Route path='/widgets/edit/:id' element={<WidgetsEdit />} />

          <Route path='/uploadata' element={<UploadData />} />
          <Route path='/uploaddata/add' element={<UploadDataAdd />} />
          <Route path='/uploaddata/view/:id' element={<UploadDataView />} />
          <Route path='/uploaddata/edit/:id' element={<UploaddataEdit />} />

          <Route path='/dashbordwidgets' element={<DashbordWidgets />} />
          <Route path='/dashbordwidgets/add' element={<DashboradWidgetsAdd />} />
          <Route path='/dashbordwidgets/view/:id' element={<DashboradWidgetsView />} />
          <Route path='/dashbordwidgets/edit/:id' element={<DashboradWidgetsEdit />} />

          <Route path='/dashboradtheme' element={<DashboradThemeicon />} />
          <Route path='/dashboradthemeicon/add' element={<DashboradThemeiconAdd />} />
          <Route path='/dashboradthemeicon/view/:id' element={<DashboradThemeiconView />} />
          <Route path='/dashboradthemeicon/edit/:id' element={<DashboradThemeiconEdit />} />

          <Route path='/stories' element={<Stories />} />
          <Route path='/stories/add' element={<StoriesAdd />} />
          <Route path='/stories/view/:id' element={<StoriesView />} />
          <Route path='/stories/edit/:id' element={<StoriesEdit />} />

          <Route path='/feedback' element={<FeedBack />} />
          <Route path='*' element={<Error />} />
          <Route path='/terms-of-service' element={<Terms />} />
        </Routes>
      </BrowserRouter>
      {/* </HashRouter> */}
    </>
  )
}


export default App;
