import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import Pagination from 'rc-pagination';
import { API } from "../../App"
import Cookies from 'js-cookie'

var Sr = [];
const MainCatgory = () => {
    const navigate = useNavigate()
    const [data, setdata] = useState([])
    const [dataLength, setdataLength] = useState(1)
    const [current, setCurrent] = useState(1);
    const [iconcoror, Seticoncoror] = useState("name_up")
    const [loading, setLoading] = useState(false)
    const [size, setSize] = useState(10);
    const [search, setSearch] = useState({
        name: "",
        image_field: ""
    })

    const getdata = async () => {
        // setLoading(true)
        const token = Cookies.get('jwt')
        const res = await API.post('/mainCategoriesData', {}, { headers: { "authorization": `Bearer ${token}` } })
        if (res.data.response_code === 0) {
            navigate("/")
            setLoading(false)
        }
        else {
            setdata(res.data.data)
            res.data.data.map((val) => {
                Sr.push(val._id)
            })
            setLoading(false)
        }
    }
    useEffect(() => {
        getdata()
    }, [])



    const change = async (id, e, name, ind) => {

        var Status
        Status = (e === true) ? true : false
        toast.success("Update Data sucessfully", { position: "bottom-right", autoClose: 500 })
        if (name == "image_field") {

            const data1 = data
            data1[ind].status = Status
            setdata(data1)

            const Form = new FormData()
            Form.append('id', id)
            Form.append('name', name)
            Form.append('Status', Status)

            const res = await API.post("/updateStatus", Form)
            console.log(res)
            getdata()
        }

    }

    const SerachData = async (e) => {
        let searching = {
            author: "",
            catagory: ""
        }
        setSearch({ ...search, [e.target.name]: e.target.value })

        const res = await API.post(`/SerchData`, { name: (e.target.name === "name") ? e.target.value : search.name, image_field: (e.target.name === "image_field") ? e.target.value : search.image_field })
        setdata(res.data.data)
        if (res.data.data.length === 0) {
            setdataLength(0)
        } else {
            setdataLength(1)

        }

    }

    const getData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {

        setSize(value);

        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }


    const deleteUser = async (_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-3'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dele = await API.post(`/mainCategoriesDelete/${_id}`)

                getdata()
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })

        console.log(_id);

    }

    const sorting = (col, order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            if (col === 'image_field' || col === 'is_premium') {
                const sorted = [...data].sort((a, b) =>
                    a[col] === b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else {
                const sorted = [...data].sort((a, b) =>
                    a[col] > b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            }
        }
        if (order === "DSC") {
            if (col === 'image_field' || col === 'is_premium') {
                const sorted = [...data].sort((a, b) =>
                    a[col] === b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else {
                const sorted = [...data].sort((a, b) =>
                    a[col] < b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            }
        }
    }


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Main Categories</h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={12}>
                        <Form.Control type="text" name="name" id="" placeholder="Search Name" onChange={SerachData} className="my-1" />
                    </Col>
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1' name='image_field' onChange={SerachData} >
                            <option value="">Select Images Filed</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }}>
                            {
                                [10, 25, 50, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Link to="/maincatgory/add">
                            <Button variant="primary" className='my-1' value="create">Add New</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                    {
                                        loading ? <div className='loader table-loader' ></div>:<></>
                                    }
                                <Table bordered responsive id="myTable">
                                    <thead>
                                        <tr>
                                            <th width="5%" className='text-center'>
                                                {/* <div className='table-sort-filter'> */}
                                                No
                                                {/* <span className='table-sort'> */}
                                                {/* <i className={`bx bxs-up-arrow ${iconcoror === "index_up" ? "active" : ""}`} id="index_up" onClick={(e) => { sorting('index', "ASC", e) }} ></i> */}
                                                {/* <i className={`bx bxs-down-arrow ${iconcoror === "index_down" ? "active" : ""}`} id="index_down" onClick={(e) => { sorting('index', "DSC", e) }} ></i> */}
                                                {/* </span> */}
                                                {/* </div> */}
                                            </th>
                                            <th width="65%">
                                                <div className='table-sort-filter'>
                                                    Name
                                                    {data.length>1?
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "ASC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "DSC", e) }} ></div>
                                                    </span>
                                                     :""}
                                                </div>
                                            </th>
                                            <th width="15%">
                                                <div className='table-sort-filter justify-content-center'>
                                                    Image Filed
                                                    {data.length>1?
                                                    <span className='table-sort'>
                                                        <i className={`sort-down ${iconcoror === "image_field_up" ? "active" : ""}`} id="image_field_up" onClick={(e) => { sorting('image_field', "ASC", e) }} ></i>
                                                        <i className={`sort-up ${iconcoror === "image_field_down" ? "active" : ""}`} id="image_field_down" onClick={(e) => { sorting('image_field', "DSC", e) }} ></i>
                                                    </span>
                                                     :""}
                                                </div>
                                            </th>
                                            <th width="15%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getData(current, size).map((val, id) => {
                                                return (
                                                    <tr key={id}>
                                                        <td className='text-center'>{id + 1}</td>
                                                        <td>{val.name}</td>
                                                        <td className='text-center'>

                                                            <Switch
                                                                onChange={(e) => { change(val._id, e, "image_field", id) }}
                                                                checked={val.image_field === true ? true : false}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className='react-switch-off'>NO</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className='react-switch-on'>YES</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to={`/maincatgory/view/${val._id}`}>
                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                            </Link>
                                                            <Link to={"/maincatgory"}>
                                                                <Button variant="outline-danger" size="sm" className="btn-icon"><i className='bx bx-trash-alt' onClick={() => deleteUser(val._id)} ></i></Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    {

                                        dataLength === 0 ? <tr>
                                            <td colSpan="100%" className="p-0">
                                                <div className='no-found'>
                                                    <img src="../../not-found/main-category.svg" />
                                                    <p>No Found Main Categories List</p>
                                                </div>
                                            </td>
                                        </tr> : ""

                                    }
                                </Table>
                                {(data.length > size) ?
                                    <div className="pagination-custom">
                                        <Pagination
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={data.length}
                                            current={current}
                                            // showLessItems={wSize<575?true:false}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                            showTitle={false}
                                        />
                                    </div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout >
    )
}

export default MainCatgory