import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import Cookies from 'js-cookie'

const UploadDataView = () => {
    const { id } = useParams()
    const [category, setCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const [user, setuser] = useState({
        name: "",
        zip_name: "",
        created_at: "",
        updated_at: "",

    })

    const [LoadData, setLoadData] = useState([])
    let navigate = useNavigate();

    const loadData = async (_id) => {
        setLoading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/GetuploadDatas/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setLoadData(res.data.data)
        const viewData = await API.post(`/uploadDatas/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setCategory(viewData.data.data._id)
        setuser({
            name: viewData.data.data.name,
            zip_name: viewData.data.data.zip_name,
            created_at: viewData.data.data.created_at,
            updated_at: viewData.data.data.updated_at

        })
        setLoading(false)
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)

    }, [])
    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/uploadata" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Upload Data</h3>
                <Row className="align-items-center">
                    {/* <Col lg={3} xs={6}>
                        <Form.Select className="my-1 def-select-custom wv-200" onChange={(e) => { getCategory(e) }}>
                            {
                                LoadData.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id} selected={user.name === dataVal.name ? true : false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col> */}
                    <Col md="auto" xs={6}>
                        <Link to={`/uploaddata/edit/${category} `}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>

            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={12}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p> <span>{user.name}</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>ZipName</p>
                                        <a download="GFG" href={user.zip_name}>
                                            <Button variant="outline-primary" size="sm" className="me-2 btn-icon Zip-downlode-btn"><i className='bx bxs-download'></i></Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default UploadDataView