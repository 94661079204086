import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import Cookies from 'js-cookie'


const StoriesView = () => {
    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [category, setCategory] = useState([])
    const [data, setdata] = useState([])
    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        main_image: [],
        front_image: "",
        zip_name: "",
        is_premium: "",
        status: "",
        created_at: "",
        updated_at: ""
    })

    const [LoadData, setLoadData] = useState([])

    const loadData = async (_id) => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/storiesData/`)
        console.log(res, "1");
        setLoadData(res.data.data)
        const viewData = await API.post(`/stories/${_id}`)
        console.log(viewData, "2");
        setCategory(viewData.data.data[0]._id)
        console.log(viewData.data.data[0].rank)
        setuser({
            rank: viewData.data.data[0].rank,
            category_id: viewData.data.data[0].category_id.name,
            main_image: viewData.data.data[0].story_images_data,
            front_image: viewData.data.data[0].story_images_data[0].front_image,
            zip_name: viewData.data.data[0].story_images_data[0].zip_name,
            is_premium: viewData.data.data[0].story_images_data[0].is_premium,
            status: viewData.data.data[0].story_images_data[0].status,
            created_at: viewData.data.data[0].created_at,
            updated_at: viewData.data.data[0].updated_at


        })
        setdata(viewData.data.data[0].story_images_data)
        Setloading(false)
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        loadData(id)

    }, [])



    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/stories" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Stories</h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Link to={`/stories/edit/${category} `}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Fancybox>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>New Index</p> <span>{user.rank}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Category Id</p> <span>{user.category_id}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Main Image</p>
                                        {
                                            user.main_image.map((val, id) => {
                                                return (
                                                    <Fancybox>
                                                    <a data-fancybox="gallery" href={val.main_image}>
                                                        <img src={val.main_image} className='hv-40 rounded-3 me-2' alt="Main Image" />
                                                    </a>
                                                    </Fancybox>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Front Image</p>
                                        <span>
                                            <Fancybox>
                                            <a data-fancybox="gallery" href={user.front_image}>
                                                <img src={user.front_image} className='hv-40 rounded-3' alt="Front Image" />
                                            </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span>{user.status === 1 ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p> <span>{user.is_premium === 1 ? "true" : "false"}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default StoriesView