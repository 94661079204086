import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
// import Fancybox from '../../Component/FancyBox';
import Switch from "react-switch";
import { API } from '../../App';
import Pagination from 'rc-pagination';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Fancybox from '../../Component/FancyBox';


var Sr = []
const Stories = () => {

    const [data, setdata] = useState([])
    const [loading, Setloading] = useState(true)
    const [cat, setCategory] = useState([])
    const navigate = useNavigate()
    const [dataLength, setdataLength] = useState(1)
    const [current, setCurrent] = useState(1);
    const [iconcoror, Seticoncoror] = useState("category_id_up")
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [search, setSearch] = useState({
        category_id: "",
        name: "",
        is_premium: "",
        status: "",
        type: ""
    })
    var arr = []


    const getdata = async (sortdata) => {
        if (search.category_id !== "" || search.is_premium !== "" || search.status !== "" || search.type !== "") {
            const res = await API.post(`/SerchDataStory`, { is_premium: search.is_premium, category_id: search.category_id, type: search.type, status: search.status })
            console.log(res, "---res");
            setdata(res.data.data)
            if (res.data.data.length === 0) {
                setdataLength(0)
            } else {
                setdataLength(1)

            }
        } else {
            Setloading(true)
            const token = Cookies.get('jwt')
            const res = await API.post("/storiesData", {}, { headers: { "authorization": `Bearer ${token}` } })
            console.log(res)
            if (res.data.response_code === 0) {
                navigate("/")
            }
            else {
                setdata(res.data.data)
                res.data.data.map((val) => {
                    Sr.push(val._id)
                })
                Setloading(false)
            }
        }
    }

    useEffect(() => {
        getdata()
    }, [])


    const lodadata = async () => {
        const catRes = await API.post("/Story_category")
        console.log(catRes, "cat");
        setCategory(catRes.data.data[0].get_SubCategory)

    }

    useEffect(() => {
        lodadata()
    }, [])


    const change = async (id, e, name, ind) => {

        var Status
        Status = (e === true) ? 1 : 0
        toast.success("Update Data sucessfully", { position: "bottom-right", autoClose: 500 })
        if (name === "status") {

            const data1 = data
            data1[ind].status = Status
            setdata(data1)
        }
        else if (name === 'is_premium') {
            const data1 = data
            data1[ind].is_premium = Status
            setdata(data1)
        }
        else {
            const data1 = data
            data1[ind].type = Status
            setdata(data1)
        }
        const Form = new FormData()
        Form.append('id', id)
        Form.append('name', name)
        Form.append('Status', Status)

        const res = await API.post("/updateStatusStory", Form)
        console.log(res, "------res")
        getdata()


    }


    const SerachData = async (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value })

        const res = await API.post(`/SerchDataStory`, { is_premium: (e.target.name === "is_premium") ? e.target.value : search.is_premium, category_id: (e.target.name === "category_id") ? e.target.value : search.category_id, type: (e.target.name === "type") ? e.target.value : search.type, status: (e.target.name === "status") ? e.target.value : search.status })
        console.log(res, "---res");
        setdata(res.data.data)
        if (res.data.data.length === 0) {
            setdataLength(0)
        } else {
            setdataLength(1)

        }

    }


    const getData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {

        setSize(value);

        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }


    const deleteUser = async (_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-3'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dele = await API.post(`/storiesDel/${_id}`)
                getdata()
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })

    }


    const sorting = (col, order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            if (col === "type") {
                const sorted = [...data].sort((a, b) =>
                    a[col] === b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else if (col === 'status' || col === 'is_premium') {
                const sorted = [...data].sort((a, b) =>
                    a.story_images_data[0][col] === b.story_images_data[0][col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else {
                const sorted = [...data].sort((a, b) =>
                    a[col] > b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            }
        }
        if (order === "DSC") {
            if (col === "type") {
                const sorted = [...data].sort((a, b) =>
                    a[col] === b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else if (col === 'status' || col === 'is_premium') {
                const sorted = [...data].sort((a, b) =>
                    a.story_images_data[0][col] === b.story_images_data[0][col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            } else {
                const sorted = [...data].sort((a, b) =>
                    a[col] < b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setdata(sorted)
                }
            }
        }
    }

    const handleDragEnd = async (e) => {
        if (!e.destination) return;
        let tempData = Array.from(data);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setdata(tempData);
        tempData.map((val, index) => {
            arr.push({ id: val._id, rank: index + 1 })
        })
        const result = await API.post('/Updateindexstories', arr)
        console.log(result)
        if (result.data.response_code === 1) {
            toast.success(result.data.response_message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
                closeOnClick: true
            });
            getdata()
        }
        else {
            toast.error(result.data.response_message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
                closeOnClick: true,
            });
        }
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Stories</h3>
                <Row className="align-items-center">
                    {/* <Col md="auto" xs={12}>
                    <Form.Control type="text" name="name" id="" placeholder="Search Name"  className="my-1" />
                </Col> */}
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1' name='category_id' onChange={SerachData}>
                            <option value="">Select Category</option>
                            {
                                cat.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1' name='is_premium' onChange={SerachData}>
                            <option value="">Select Premium</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1' name='status' onChange={SerachData}>
                            <option value="">Select Status</option>
                            <option value={1}>OFF</option>
                            <option value={0}>ON</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Form.Select className='my-1' name='type' onChange={SerachData}>
                            <option value="">Select Type</option>
                            <option value={0}>iOS</option>
                            <option value={1}>Android</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }}>
                            {
                                [10, 25, 50, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={12}>
                        <Link to="/stories/add">
                            <Button variant="primary" className='my-1' value="create">Add New</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    {
                                        loading ? <div className='loader table-loader' ></div> : <></>
                                    }
                                    <Table bordered responsive id="myTable">
                                        <thead>
                                            <tr>
                                                <th width="5%" className='text-center'>No</th>
                                                <th width="15%">
                                                    <div className='table-sort-filter'>
                                                        Category Id
                                                        {data.length > 1 ?
                                                            <span className='table-sort'>
                                                                <i className={`sort-down ${iconcoror === "category_id_up" ? "active" : ""}`} id="category_id_up" onClick={(e) => { sorting('category_id', "ASC", e) }} ></i>
                                                                <i className={`sort-up ${iconcoror === "category_id_down" ? "active" : ""}`} id="category_id_down" onClick={(e) => { sorting('category_id', "DSC", e) }} ></i>
                                                            </span>
                                                            : ""}
                                                    </div>
                                                </th>
                                                <th width="25%" className='text-center'>Main Image </th>
                                                <th width="15%" className='text-center'>Front Image</th>
                                                <th width="10%">
                                                    <div className='table-sort-filter justify-content-center'>
                                                        Is Premium
                                                        {data.length > 1 ?
                                                            <span className='table-sort'>
                                                                <i className={`sort-down ${iconcoror === "is_premium_up" ? "active" : ""}`} id="is_premium_up" onClick={(e) => { sorting('is_premium', "ASC", e) }} ></i>
                                                                <i className={`sort-up ${iconcoror === "is_premium_down" ? "active" : ""}`} id="is_premium_down" onClick={(e) => { sorting('is_premium', "DSC", e) }} ></i>
                                                            </span>
                                                            : ""}
                                                    </div>
                                                </th>
                                                <th width="10%">
                                                    <div className='table-sort-filter justify-content-center'>
                                                        Status
                                                        {data.length > 1 ?
                                                            <span className='table-sort'>
                                                                <i className={`sort-down ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "ASC", e) }} ></i>
                                                                <i className={`sort-up ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "DSC", e) }} ></i>
                                                            </span>
                                                            : ""}
                                                    </div>
                                                </th>
                                                <th width="10%">
                                                    <div className='table-sort-filter justify-content-center'>
                                                        Type
                                                        {data.length > 1 ?
                                                            <span className='table-sort'>
                                                                <i className={`sort-down ${iconcoror === "type_up" ? "active" : ""}`} id="type_up" onClick={(e) => { sorting('type', "ASC", e) }} ></i>
                                                                <i className={`sort-up ${iconcoror === "type_down" ? "active" : ""}`} id="type_down" onClick={(e) => { sorting('type', "DSC", e) }} ></i>
                                                            </span>
                                                            : ""}
                                                    </div>
                                                </th>
                                                <th width="10%" className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                                    {
                                                        getData(current, size).map((val, id) => (
                                                            console.log(val.status,"======status"),
                                                            <Draggable key={val._id} draggableId={val._id} index={id}>
                                                                {(provider) => (
                                                                    <tr key={id} {...provider.draggableProps} ref={provider.innerRef} {...provider.dragHandleProps}>
                                                                        <td className='text-center'>{id + 1}</td>
                                                                        <td>{val.category_id.name}</td>
                                                                        <td className='text-center inner-img'>
                                                                            {
                                                                                val.story_images_data.map((valid) => {
                                                                                    return (
                                                                                        (valid.main_image === "" || valid.main_image === null) ? "" :
                                                                                            <Fancybox>
                                                                                                <a data-fancybox="gallery" href={valid.main_image}>
                                                                                                    <img src={valid.main_image} className='hv-40 bg-light' alt='Main Image' />
                                                                                                </a>
                                                                                            </Fancybox>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            {(val.story_images_data[0].front_image === "" || val.story_images_data[0].front_image === null) ? "" :
                                                                                <Fancybox>
                                                                                    <a data-fancybox="gallery" href={val.story_images_data[0].front_image}>
                                                                                        <img src={val.story_images_data[0].front_image} className='hv-40 bg-light' alt='Front Image' />
                                                                                    </a>
                                                                                </Fancybox>
                                                                                }
                                                                        </td>
                                                                        <td className='text-center'>

                                                                            <Switch
                                                                                onChange={(e) => { change(val._id, e, "is_premium", id) }}
                                                                                checked={val.is_premium === 1 ? true : false}
                                                                                offColor="#C8C8C8"
                                                                                onColor="#0093ed"
                                                                                height={30}
                                                                                width={70}
                                                                                className="react-switch"
                                                                                uncheckedIcon={
                                                                                    <div className='react-switch-off'>NO</div>
                                                                                }
                                                                                checkedIcon={
                                                                                    <div className='react-switch-on'>YES</div>
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-center'>

                                                                            <Switch
                                                                                onChange={(e) => { change(val._id, e, "status", id) }}
                                                                                checked={val.status === 1 ? true : false}
                                                                                offColor="#C8C8C8"
                                                                                onColor="#0093ed"
                                                                                height={30}
                                                                                width={70}
                                                                                className="react-switch"
                                                                                uncheckedIcon={
                                                                                    <div className='react-switch-off'>OFF</div>
                                                                                }
                                                                                checkedIcon={
                                                                                    <div className='react-switch-on'>ON</div>
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-center'>

                                                                            <Switch
                                                                                onChange={(e) => { change(val._id, e, "type", id) }}
                                                                                checked={val.type === 1 ? true : false}
                                                                                offColor="#C8C8C8"
                                                                                onColor="#0093ed"
                                                                                height={30}
                                                                                width={70}
                                                                                className="react-switch"
                                                                                uncheckedIcon={
                                                                                    <div className='react-switch-off'>iOS</div>
                                                                                }
                                                                                checkedIcon={
                                                                                    <div className='react-switch-on'>AOS</div>
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Link to={`/stories/view/${val._id}`}>
                                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                                            </Link>

                                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(val._id)}><i className='bx bx-trash-alt'></i></Button>

                                                                        </td>
                                                                    </tr>
                                                                )}

                                                            </Draggable>

                                                        ))
                                                    }
                                                </tbody>
                                            )}
                                        </Droppable>
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/stories.svg" />
                                                        <p>No Found Stories List</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </Table>
                                </DragDropContext>

                                {(data.length > size) ?
                                    <div className="pagination-custom">
                                        <Pagination
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={data.length}
                                            current={current}
                                            // showLessItems={wSize<575?true:false}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                            showTitle={false}
                                        />
                                    </div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>

    )
}

export default Stories