import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { API } from '../../App';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'
import Fancybox from '../../Component/FancyBox';


const ThemeIconEdit = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [LoadData, setLoadData] = useState([])
    const [validate, setValidate] = useState(false)
    const [IsSelected, setIsSelected] = useState(false);
    const [selectedfile, setSelectedfile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selecteds, setSelects] = useState(null);
    const [selectedsd, setSelectedsd] = useState(null);
    const [loading, Setloading] = useState(true)
    const [cat, setCategory] = useState([])


    const lodadata = async () => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/themeNews/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(res.data.data[0]);
        setuser({
            rank: res.data.data[0].rank,
            category_id: res.data.data[0].categories._id,
            name: res.data.data[0].name,
            image: res.data.data[0].image,
            thumbImage: res.data.data[0].thumbImage,
            image_ipad: res.data.data[0].image_ipad,
            thumbimage_ipad: res.data.data[0].thumbimage_ipad,
            is_premium: res.data.data[0].is_premium,
            status: res.data.data[0].status,
            theme_icons_data: res.data.data[0].theme_icons_data

        })
        setLoadData(res.data.data)
        var new_data = []
        res.data.data[0].theme_icons_data
            .map((val) => {
                new_data.push(
                    {
                        icon_id: val._id,
                        icon: val.icon,
                        icon_name: val.icon_name,
                        icon_url: val.icon_url,
                    }
                )
            })
        setKey(new_data)
        const catRes = await API.post(`/ThemeNew_category`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(catRes, "----------catRes")
        const catt = catRes.data.data[0].get_SubCategory
        setCategory(catt)
        Setloading(false)
    }


    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        name: "",
        status: "",
        is_premium: "",
        theme_icons_data: []
    })

    const handdelsubmission = async () => {

        if (user.rank === "" || !user.rank) {
            setValidate(true)
            console.log('helo')
        }
        else if (user.category_id === "" || !user.category_id) {
            setValidate(true)
            console.log('helo2')
        }
        else if (user.name === "" || !user.name) {
            setValidate(true)
            console.log('helo3')
        }
        else {
            const formdata = new FormData()
            formdata.append("rank", user.rank)
            formdata.append("category_id", user.category_id)
            formdata.append("name", user.name)
            formdata.append("folder_name", user.folder_name)
            formdata.append("image", selectedfile)
            formdata.append("thumbImage", selectedFile)
            formdata.append("image_ipad", selecteds)
            formdata.append("thumbimage_ipad", selectedsd)
            formdata.append("status", user.status)
            formdata.append("is_premium", user.is_premium)
            if (key.length === 1) {
                if (key[0].icon === "") {
                    setValidate(true)

                    return
                }
                if ((key[0].icon_name === "")) {
                    setValidate(true)

                    return
                }
                if ((key[0].icon_url === "")) {
                    setValidate(true)

                    return
                }
                formdata.append('icon_id', key[0].icon_id)
                formdata.append('icon_name', key[0].icon_name)
                formdata.append('icon_url', key[0].icon_url)
                formdata.append('icon', [key[0].icon])
            } else {
                key.map((val) => {
                    if ((val.icon_name === "")) {
                        setValidate(true)

                        return
                    }
                    if ((val.icon_url === "")) {
                        setValidate(true)

                        return
                    }
                    formdata.append('icon_id', val.icon_id)
                    formdata.append('icon_name', val.icon_name)
                    formdata.append('icon_url', val.icon_url)
                    formdata.append('icon', val.icon)
                })
            }
            Setloading(true)
            const token = Cookies.get('jwt')
            const res = await API.post(`/themeNewsUpdate/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
            console.log(res)
            if (res.data.status === true) {
                Setloading(false)
                navigate("/themeicon")
                toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
            }

        }
    }

    const onInputchange = (e) => {
        console.log(e.target.value)
        setuser({ ...user, [e.target.name]: e.target.value })

    }

    const changeHandler = (event) => {
        console.log(event.target.files[0])
        setSelectedfile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlers = (event) => {
        console.log(event.target.files[0])
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlerds = (event) => {
        console.log(event.target.files[0])
        setSelects(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlerssd = (event) => {
        console.log(event.target.files[0])
        setSelectedsd(event.target.files[0]);
        setIsSelected(true);

    }

    useEffect(() => {
        lodadata()
    }, [])


    const premchange = (e) => {
        const result = (e === true) ? 1 : 0
        setuser({
            ...user, ['is_premium']: result
        })
    }

    const statuschange = (e) => {
        const result = (e === true) ? 1 : 0
        setuser({
            ...user, ['status']: result
        })
    }


    const [key, setKey] = useState([])
    const addKey = (tag1, name) => {
        const keyTag = {
            icon_id: "",
            icon: null,
            icon_name: "",
            icon_url: ""
        }
        setKey([...key, keyTag])
    }

    const removeKey = (index, _id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-3'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = [...key]
                data.splice(index, 1)
                setKey(data)
                const dele = await API.post(`/deleteTheme/${_id}`)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
        // }
    }

    const keyChange = (e, index, name) => {
        if (name === "icon") {
            console.log(e.target.files[0])
            let data = [...key];
            data[index][e.target.name] = e.target.files[0]
            setKey(data)
        } else {
            console.log(e.target.value)
            let data = [...key];
            data[index][e.target.name] = e.target.value
            setKey(data)
        }

    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Edit Theme Icon</h3>
            </div>
            <div className="page-content">
                <Form>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    {
                                        loading ? <div className='loader table-loader' ></div> :
                                            <>
                                                <Row>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                                                            <Form.Control type="number" id='cate-img' className="my-2" placeholder='Enter Index' value={user.rank} readOnly />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} xs={12}>
                                                        <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                                                        <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                                                            <option value="">Select Category Id</option>
                                                            {
                                                                cat.map((val, id) => {
                                                                    return (
                                                                        <option key={id} selected={val._id === user.category_id ? true : false} value={val._id} >{val.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="cate" >Name</Form.Label>
                                                            <Form.Control type="text" id='cate' className="my-2" placeholder='Enter Name' name='name' value={user.name} onChange={onInputchange} required />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="cate-img" >Image</Form.Label>
                                                            <InputGroup className="my-2">
                                                                <Form.Control type="file" id='cate-img'  name='image' onChange={changeHandler} required />
                                                                <Fancybox>
                                                                    <a data-fancybox="gallery" href={user.image}>
                                                                        <img src={user.image} className='hv-40 bg-light rounded-3' alt="Image" />
                                                                    </a>
                                                                </Fancybox>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="cate-img" >Thumb Image</Form.Label>
                                                            <InputGroup className="my-2">
                                                                <Form.Control type="file" id='cate-img' name='thumbImage' onChange={changeHandlers} required />
                                                                <Fancybox>
                                                                    <a data-fancybox="gallery" href={user.thumbImage}>
                                                                        <img src={user.thumbImage} className='hv-40 bg-light rounded-3' alt="Thumb Image" />
                                                                    </a>
                                                                </Fancybox>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="cate-img" >iPad Image</Form.Label>
                                                            <InputGroup className="my-2">
                                                                <Form.Control type="file" id='cate-img' name='image_ipad' onChange={changeHandlerds} required />
                                                                <Fancybox>
                                                                    <a data-fancybox="gallery" href={user.image_ipad}>
                                                                        <img src={user.image_ipad} className='hv-40 bg-light rounded-3' alt="iPad Image" />
                                                                    </a>
                                                                </Fancybox>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="cate-img" >iPad Thumb Image</Form.Label>
                                                            <InputGroup className="my-2">
                                                                <Form.Control type="file" id='cate-img' name='thumbimage_ipad' onChange={changeHandlerssd} required />
                                                                <Fancybox>
                                                                    <a data-fancybox="gallery" href={user.thumbimage_ipad}>
                                                                        <img src={user.thumbimage_ipad} className='hv-40 bg-light rounded-3' alt="iPad Thumb Image" />
                                                                    </a>
                                                                </Fancybox>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={1} sm="12">
                                                        <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                                                        <Switch
                                                            onChange={premchange}
                                                            checked={user.is_premium}
                                                            offColor="#C8C8C8"
                                                            onColor="#0093ed"
                                                            height={30}
                                                            width={70}
                                                            className="react-switch"
                                                            uncheckedIcon={
                                                                <div className='react-switch-off'>NO</div>
                                                            }
                                                            checkedIcon={
                                                                <div className='react-switch-on'>YES</div>
                                                            }
                                                        />
                                                    </Col>
                                                    <Col lg={2} sm="12">
                                                        <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                                        <Switch
                                                            onChange={statuschange}
                                                            checked={user.status}
                                                            offColor="#C8C8C8"
                                                            onColor="#0093ed"
                                                            height={30}
                                                            width={70}
                                                            className="react-switch"
                                                            uncheckedIcon={
                                                                <div className='react-switch-off'>OFF</div>
                                                            }
                                                            checkedIcon={
                                                                <div className='react-switch-on'>ON</div>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {
                                                        key.map((keyVal, ind) => {
                                                            return (
                                                                <Row>
                                                                    <Col lg={3} sm={12}>
                                                                        <Form.Control type="hidden" className="my-2" value={keyVal._id} name="icon_id" />
                                                                        <Form.Group>
                                                                            <Form.Label htmlFor="cate-img" >Image</Form.Label>
                                                                            <InputGroup className="my-2">
                                                                                <Form.Control type="file" id='cate-img' name='icon' onChange={(e) => { keyChange(e, ind, "icon") }} />
                                                                                {/* <img src={keyVal.icon} className='hv-40 bg-light' alt="" /> */}
                                                                                <Fancybox>
                                                                                    <a data-fancybox="gallery" href={keyVal.icon}>
                                                                                        <img src={keyVal.icon} className='hv-40 bg-light rounded-3' alt="" />
                                                                                    </a>
                                                                                </Fancybox>
                                                                            </InputGroup>

                                                                        </Form.Group>
                                                                        {/* <Form.Group >
                                                                <Form.Label htmlFor="cate-img">Image</Form.Label>
                                                                <div className='side-img'>
                                                                    <Form.Control type="file" id='cate-img' className="my-2 pe-5" name='icon' onChange={(e) => { keyChange(e, ind, "icon") }} required />
                                                                </div>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Select Image
                                                                </Form.Control.Feedback>
                                                            </Form.Group> */}
                                                                    </Col>
                                                                    <Col lg={3} sm={12}>
                                                                        <Form.Group >
                                                                            <Form.Label htmlFor="cate">Icon Name</Form.Label>
                                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.icon_name} name='icon_name' onChange={(e) => { keyChange(e, ind, "icon_name") }} placeholder='Enter Icon Name' required />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter Icon Name
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={3} sm={12}>
                                                                        <Form.Group >
                                                                            <Form.Label htmlFor="cate">Icon Url</Form.Label>
                                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.icon_url} name='icon_url' onChange={(e) => { keyChange(e, ind, "icon_url") }} placeholder='Enter Icon Url' required />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter Icon Url
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={2} sm={12} className="align-self-end my-3">
                                                                        <Button variant="danger" size="sm" className="btn-icon" onClick={(e) => { removeKey(ind, keyVal.icon_id) }}><i class='bx bx-x' ></i></Button>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }                                    </Row>
                                                <Row>
                                                    <Col md="auto" xs={12}>
                                                        <Button variant="primary" className='my-3' value="create" onClick={addKey}>Add More Icon</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                                    <Link to="/themeicon">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default ThemeIconEdit