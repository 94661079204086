import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Switch from "react-switch";
import { API } from '../../App';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'
import Fancybox from '../../Component/FancyBox';


const DashboradWidgetsEdit = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [LoadData, setLoadData] = useState([])
    const [validate, setValidate] = useState(false)
    const [IsSelected, setIsSelected] = useState(false);
    const [selectedfile, setSelectedfile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [cat, setCategory] = useState([])


    const lodadata = async () => {
        const token = Cookies.get('jwt')
        const res = await API.post(`/dashboardWidgets/${id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setuser({
            rank: res.data.data[0].rank,
            category_id: res.data.data[0].category_id._id,
            background_image: res.data.data[0].background_image,
            alignment: res.data.data[0].alignment,
            clock_image: res.data.data[0].clock_image,
            quote: res.data.data[0].quote,
            is_premium: res.data.data[0].is_premium,
            status: res.data.data[0].status,
            android_status: res.data.data[0].android_status,
        })
        setLoadData(res.data.data)
        console.log(res)
        var new_data = []
        res.data.data[0].dashboard_widget_data
            .map((val) => {
                new_data.push(
                    {
                        widget_name_id: val._id,
                        font_name: val.font_name,
                        color_name: val.color_name,
                    }
                )
            })
        setKey(new_data)
        const catRes = await API.post(`/dashboardWidgetsData`, {}, { headers: { "authorization": `Bearer ${token}` } })
        console.log(catRes)
        const catt = [catRes.data.data[0].category_id]
        setCategory(catt)
    }

    const [user, setuser] = useState({
        rank: "",
        category_id: "",
        alignment: "",
        quote: "",
        status: "",
        is_premium: ""

    })

    //key
    const [key, setKey] = useState([{
        widget_name_id: '',
        font_name: "",
        color_name: ""
    }])
    const addKey = (tag1, name) => {
        const keyTag = {
            font_name: "",
            color_name: ""
        }
        setKey([...key, keyTag])
    }

    const removeKey = async (index, _id) => {
        console.log(_id)

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-3'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = [...key]
                data.splice(index, 1)
                setKey(data)
                const dele = await API.post(`/deleteDeshWidget/${_id}`)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }

    const keyChange = (e, index) => {
        let data = [...key];
        data[index][e.target.name] = e.target.value
        setKey(data)
    }


    const handdelsubmission = async () => {
        if (user.rank === "" || !user.rank) {
            setValidate(true)
        } else if (user.alignment === "" || !user.alignment) {
            setValidate(true)
        }
        else if (user.category_id === "" || !user.category_id) {
            setValidate(true)
        }
        else if (user.quote === "" || !user.quote) {
            setValidate(true)
        }
        else {
            const formdata = new FormData()
            formdata.append("rank", user.rank)
            formdata.append("category_id", user.category_id)
            formdata.append("alignment", user.alignment)
            formdata.append("quote", user.quote)
            formdata.append("background_image", selectedfile)
            formdata.append("clock_image", selectedFile)
            formdata.append("status", user.status)
            formdata.append("is_premium", user.is_premium)
            if (key.length === 1) {
                if ((key[0].font_name === "")) {
                    setValidate(true)
                }
                if ((key[0].color_name === "")) {
                    setValidate(true)

                }
                console.log(key[0].font_name, "sdsdsd")
                formdata.append('widget_name_id', key[0].widget_dashboard_id)
                formdata.append('font_name', key[0].font_name)
                formdata.append('color_name', key[0].color_name)
            } else {
                key.map((val) => {
                    console.log(val.widget_name_id)
                    if ((val.font_name === "")) {
                        setValidate(true)
                        // setLoader(false)
                        return
                    }
                    if ((val.color_name === "")) {
                        setValidate(true)
                        // setLoader(false)
                        return
                    }
                    formdata.append('widget_name_id', val.widget_name_id)
                    formdata.append('font_name', val.font_name)
                    formdata.append('color_name', val.color_name)
                })
            }
            const token = Cookies.get('jwt')
            const res = await API.post(`/dashboardWidgetsUpd/${id}`, formdata, { headers: { "authorization": `Bearer ${token}` } })
            console.log(res)
            if (res.data.status === true) {
                navigate("/dashbordwidgets")
                toast.success("Data Save Sucessfully", { position: "bottom-right", autoClose: 500 })
            }

        }
    }

    const onInputchange = (e) => {
        console.log(e.target.value)
        setuser({ ...user, [e.target.name]: e.target.value })

    }

    const changeHandler = (event) => {
        console.log(event.target.files[0])
        setSelectedfile(event.target.files[0]);
        setIsSelected(true);

    }

    const changeHandlers = (event) => {
        console.log(event.target.files[0])
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);

    }

    const lodaData = async () => {
        const catRes = await API.post("/widget_category")
        setCategory(catRes.data.data[0].get_SubCategory)

    }
    useEffect(() => {
        lodadata()
        lodaData()
    }, [])


    const statuschange = (e) => {
        const result = (e === true) ? true : false
        setuser({
            ...user, ['status']: result
        })
    }

    const premchange = (e) => {
        const result = (e === true) ? true : false
        setuser({
            ...user, ['is_premium']: result
        })
    }


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Edit Dashborad Widgets</h3>
                <Row className="align-items-center">
                </Row>
            </div>
            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col lg={3} sm={12}>
                                            <Form.Group >
                                                <Form.Label htmlFor="m_cate" >New Index</Form.Label>
                                                <Form.Control type="number" id='cate-img' className="my-2" value={user.rank} name='rank' readOnly />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} xs={12}>
                                            <Form.Label htmlFor='m_cate'>Category Id</Form.Label>
                                            <Form.Select className='my-2' name='category_id' onChange={onInputchange} required>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Category Id
                                                </Form.Control.Feedback>
                                                <option value="">Select Category Id</option>
                                                {
                                                    cat.map((val, id) => {
                                                        return (
                                                            <option key={id} selected={val._id === user.category_id ? true : false} value={val._id} >{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group>
                                                <Form.Label htmlFor="cate-img" >Background Image</Form.Label>
                                                <InputGroup className="my-2">
                                                    <Form.Control type="file" id='cate-img' name='background_image' onChange={changeHandler} />
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" href={user.background_image}>
                                                            <img src={user.background_image} className='hv-40 bg-light rounded-3' alt="Background Image" />
                                                        </a>
                                                    </Fancybox>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group>
                                                <Form.Label htmlFor="cate-img" >Clock Image</Form.Label>
                                                <InputGroup className="my-2">
                                                    <Form.Control type="file" id='cate-img' name='clock_image' onChange={changeHandlers} />
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" href={user.background_image}>
                                                            <img src={user.clock_image} className='hv-40 bg-light rounded-3' alt="Clock Image" />
                                                        </a>
                                                    </Fancybox>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} xs={12}>
                                            <Form.Label htmlFor='m_cate'>Alignment</Form.Label>
                                            <Form.Select className='my-2' name='alignment' onChange={onInputchange} required>
                                                <option value="">Select Alignment</option>
                                                <option value='right' selected={user.alignment === 'right' ? true : false}>Right</option>
                                                <option value='center' selected={user.alignment === 'center' ? true : false}>Center</option>
                                                <option value='left' selected={user.alignment === 'left' ? true : false}>Left</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select Alignment
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Group >
                                                <Form.Label htmlFor="cate" >Quote</Form.Label>
                                                <Form.Control type="text" id='cate' className="my-2" value={user.quote} placeholder='Enter Quote' name='quote' onChange={onInputchange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Quote
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Premium</Form.Label>
                                            <Switch
                                                onChange={premchange}
                                                checked={user.is_premium === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>NO</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col lg={3} sm="12">
                                            <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                            <Switch
                                                onChange={statuschange}
                                                checked={user.status === true ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className='react-switch-off'>NO</div>
                                                }
                                                checkedIcon={
                                                    <div className='react-switch-on'>YES</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="auto" xs={12}>
                                            <Button variant="primary" className='my-3' value="create" onClick={addKey}>Add More Icon</Button>
                                        </Col>
                                    </Row>
                                    {
                                        key.map((keyVal, ind) => {
                                            return (
                                                <Row>
                                                    <Form.Control type="hidden" value={keyVal.widget_name_id} name='widget_name_id' />
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="cate">Color Name</Form.Label>
                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.color_name} name='color_name' onChange={(e) => { keyChange(e, ind) }} placeholder='Enter Color Name' required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Color Name
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} sm={12}>
                                                        <Form.Group >
                                                            <Form.Label htmlFor="cate">Font Name</Form.Label>
                                                            <Form.Control type="text" id='cate' className="my-2" value={keyVal.font_name} name='font_name' onChange={(e) => { keyChange(e, ind) }} placeholder='Enter Font Name' required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Enter Font Name
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    {ind !== 0 ? <Col lg={2} sm={12} className="align-self-end my-3">
                                                        <Button variant="danger" size="sm" className="btn-icon" onClick={(e) => { removeKey(ind, keyVal.widget_name_id) }}><i class='bx bx-x' ></i></Button>
                                                    </Col> : ""}
                                                </Row>
                                            )
                                        })
                                    }
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={handdelsubmission}>Save</Button>
                                    <Link to="/dashbordwidgets">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default DashboradWidgetsEdit