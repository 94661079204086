import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import Cookies from 'js-cookie'


const LivethemeView = () => {
    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [category, setCategory] = useState([])
    const [user, setuser] = useState({
        rank: "",
        thumb_image: "",
        name: "",
        btn_Back_Color: "",
        btn_Key_Color: "",
        popup_Back_Color: "",
        popup_Text_Color: "",
        is_Border: "",
        loop_Mode: "",
        json_name: "",
        status: "",
        border_Color: "",
        view_Back_Color: "",
        text_Color_For_Show_Key_Off: "",
        created_at: "",
        updated_at: "",
        is_premium: "",
        is_Watch_Video: ""
    })

    const [LoadData, setLoadData] = useState([])
    let navigate = useNavigate();

    const loadData = async (_id) => {
        Setloading(true)
        const token = Cookies.get('jwt')
        const res = await API.post(`/themesData/`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setLoadData(res.data.data)
        const viewData = await API.post(`/themes/${_id}`, {}, { headers: { "authorization": `Bearer ${token}` } })
        setCategory(viewData.data.data._id)
        setuser({
            rank: viewData.data.data.rank,
            thumb_image: viewData.data.data.thumb_image,
            name: viewData.data.data.name,
            btn_Back_Color: viewData.data.data.btn_Back_Color,
            btn_Key_Color: viewData.data.data.btn_Key_Color,
            popup_Back_Color: viewData.data.data.popup_Back_Color,
            popup_Text_Color: viewData.data.data.popup_Text_Color,
            is_Border: viewData.data.data.is_Border,
            loop_Mode: viewData.data.data.loop_Mode,
            json_name: viewData.data.data.json_name,
            status: viewData.data.data.status,
            border_Color: viewData.data.data.border_Color,
            view_Back_Color: viewData.data.data.view_Back_Color,
            text_Color_For_Show_Key_Off: viewData.data.data.text_Color_For_Show_Key_Off,
            is_premium: viewData.data.data.is_premium,
            is_Watch_Video: viewData.data.data.is_Watch_Video,
            created_at: viewData.data.data.created_at,
            updated_at: viewData.data.data.updated_at


        })
        Setloading(false)
    }

    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/homePageApi", {}, { headers: { "authorization": `Bearer ${token}` } })
        if (response.data.response_code === 0) {
            navigate("/")
        }
    }

    const getCategory = (e) => {
        setCategory(e.target.value)
        loadData(e.target.value)
    }

    useEffect(() => {
        tokenAuth()
        loadData(id)

    }, [])

    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/livetheme" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Live theme - <span>{user.name}</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={(e) => { getCategory(e) }}>
                            {
                                LoadData.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} value={dataVal._id} selected={user.name === dataVal.name ? true : false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/livetheme/edit/${category}`}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {
                            loading ? <div className='loader table-loader' ></div> : <></>
                        }
                        <Fancybox>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>New Index</p> <span>{user.rank}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p> <span>{user.name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Thumb Images</p>
                                        <span>
                                            <Fancybox>
                                                <a data-fancybox="gallery" href={user.thumb_image}>
                                                    <img src={user.thumb_image} className='hv-30 rounded-3' alt="Thumb Images" />
                                                </a>
                                            </Fancybox>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>JSON File</p>
                                        <span>
                                            <a download="GFG" href={user.json_name}>
                                                <Button className='Zip-downlode-btn btn-icon'><i className='bx bxs-download'></i></Button>
                                            </a>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Premium</p> <span>{user.is_premium === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p> <span> {user.status === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is WatchVideo</p> <span>{user.is_Watch_Video === true ? "true" : "false"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Loop Mode</p> <span>{user.loop_Mode === false ? "For Loop" : "Auto Reverse"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>BTN BackColor</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.btn_Back_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.btn_Back_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Button KeyColor</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.btn_Key_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.btn_Key_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Popup BackColor</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.popup_Back_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.popup_Back_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Popup TextColor</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.popup_Text_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.popup_Text_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Border Color</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.border_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.border_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>View BackColor</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.view_Back_Color} className="my-2" disabled />
                                                <div className='color-box' style={{ backgroundColor:user.view_Back_Color }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>TextColor For ShowKeyOff</p> 
                                        <span>
                                            <div className='color-box-group'>
                                                <Form.Control type="color" value={user.text_Color_For_Show_Key_Off} className="my-2" disabled /> 
                                                <div className='color-box' style={{ backgroundColor:user.text_Color_For_Show_Key_Off }}></div>
                                            </div>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Fancybox>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default LivethemeView